/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  DropDownSelect,
  SearchableDropdown,
} from "../components/form/Dropdown";
import { Button } from "../components/Button";
import { PageLayout } from "../components/Layout";
import {
  useGetCostPriceMutation,
  useGetImportCostPriceMutation,
  useGetIntlCostPriceMutation,
  useGetIntlWeightQuery,
} from "../services/slices/costSlice";
import {
  useGetDropOffZonesQuery,
  useGetIntlZonesMutation,
  useGetPickUpZonesQuery,
} from "../services/slices/getZonesSlice";
import { Input } from "../components/form/Input";
import { DashboardModalAction, ErrorModal } from "../components/Modal";
import { object, string, number } from "yup";
import { useGetImportLocationsQuery } from "../services/slices/import";
import getSymbolFromCurrency from "currency-symbol-map";
import SelectInput from "../components/form/SelectInput";
import { useNavigate } from "react-router-dom";

const DeliveryEstimate = () => {
  const [pickUpZoneData, setPickupZoneData] = useState([]);
  const [dropOffZoneData, setDropOffZoneData] = useState([]);
  const [zoneOption, setZoneOption] = useState<any>(null);
  const [zoneOptionTwo, setZoneOptionTwo] = useState<any>(null);
  const [countryOption, setCountryOption] = useState<any>(null);
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [queryTwo, setQueryTwo] = useState("");
  const [queryThree, setQueryThree] = useState("");
  const [showAddresses, setShowAddresses] = useState<any>([]);
  const [showClipboard, setShowClipboard] = useState(false);
  const [copiedAdd, setCopiedAdd] = useState("");
  const [isOpenWeight, setIsOpenWeight] = useState(false);
  const [cost, setCost] = useState("");
  const [queryDeliveryType, setQueryDeliveryType] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [infoErrorMessage, setInfoErrorMessage] = useState("");
  const [queryDeliveryValue, setQueryDeliveryValue] = useState("Local");
  const [queryCountry, setQueryCountry] = useState("");
  const [costError, setCostError] = useState("no");
  const [isOpenTwo, setIsOpenTwo] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [isOpenDeliveryType, setIsOpenDeliveryType] = useState(false);
  const [isOpenCountry, setIsOpenCountry] = useState(false);
  const [isOpenRequestType, setIsOpenRequestType] = useState(false);
  const { data: pickUpData } = useGetPickUpZonesQuery();
  const { data: dropOffData } = useGetDropOffZonesQuery();
  const [weight, setWeight] = useState({
    id: null,
    name: "",
  });
  const [getIntlZones, { data: intlZonesData, isLoading: isLoadingIntlZones }] =
    useGetIntlZonesMutation();
  const [locationData, setLocationData] = useState<any>([]);
  const [getCostPrice, { data: costData, isLoading, isSuccess, isError }] =
    useGetCostPriceMutation();
  const [
    getIntlCost,
    {
      data: intlCostData,
      isSuccess: isSendSuccess,
      isLoading: isLoadingIntlCost,
    },
  ] = useGetIntlCostPriceMutation();
  const { data: intlWeightData } = useGetIntlWeightQuery();
  const { data: importLocations } = useGetImportLocationsQuery();

  const [
    getImportCostPrice,
    {
      data: costIntlData,
      isLoading: isIntlCostLoading,
      isSuccess: isIntlSuccess,
      isError: isIntlCostError,
    },
  ] = useGetImportCostPriceMutation();
  const [dispatchFirstData, setDispatchFirstData] = useState({
    requestType: "",
    pickUpZone: "",
    dropOffZone: "",
    deliveryType: "bike",
    deliveryMode: "bike",
    pickUpLocationId: "",
    dropOffLocationName: "",
    dropOffLocationId: "",
    weight: "",
    country: "",
  });
  const [infoErrorMessages, setInfoErrorMessages] = useState({
    pickUpZone: "",
    dropOffZone: "",
    weight: "",
    country: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (importLocations) {
      if (importLocations?.data?.data) {
        setLocationData(importLocations?.data?.data);
      }
    }
  }, [importLocations]);

  useEffect(() => {
    if (queryDeliveryValue.toLowerCase() === "send from nigeria") {
      getIntlZones(weight?.id);
    }
  }, [queryDeliveryValue, weight]);

  const focusHandleCountry = () => {
    if (queryCountry === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        country: "Country is required",
      }));
    }
    if (queryCountry !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        country: "",
      }));
    }
  };

  let userSchema = object({
    pickUpZone: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            pickUpZone: "Pickup zone  is required",
          }));
          setInfoErrorMessage("Pickup zone  is required");
          throw new TypeError("Pickup zone  is required");
        }
        return value;
      })
      .required("Pickup zone  is required"),
    dropOffZone: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            dropOffZone: "Dropoff zone  is required",
          }));
          setInfoErrorMessage("Dropoff zone  is required");
          throw new TypeError("Dropoff zone  is required");
        }
        return value;
      })
      .required("Pickup zone  is required"),

    weight: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            weight: "weight must be a valid number",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            weight: "weight is required",
          }));

          setInfoErrorMessage("weight must be a valid number");
          throw new TypeError("weight must be a valid number");
        }

        return value;
      })
      .required("")
      .positive()
      .integer(""),
  });

  const checkValidate = async () => {
    try {
      await userSchema.validate(dispatchFirstData, { abortEarly: false });
      setInfoErrorMessage("");
    } catch (err: any) {
      const errorMessage = err?.errors?.join(", ");
      setInfoErrorMessage(errorMessage);
    }
  };
  const handleChange = async (evt: any) => {
    const { name, value } = evt.target;

    setDispatchFirstData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "weight" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, weight: "" }));
    }
    if (name === "weight" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, weight: "" }));
    }

    try {
      await userSchema.validateAt(name, { [name]: value });
    } catch (error: any) {
      if (error) {
        setInfoErrorMessage(error.message);
      }
    }
  };

  const getCostPriceData = async () => {
    setCostError("no");
    try {
      const requestPayload = {
        ori_zone: dispatchFirstData.pickUpZone,
        des_zone: dispatchFirstData.dropOffZone,
        deliveryType: dispatchFirstData.deliveryType,
        weight: Number(dispatchFirstData.weight),
      };

      await getCostPrice(requestPayload).unwrap();
    } catch (error) {
      setCostError("Network error!!!");
    }
  };

  const getIntlCostPriceData = async () => {
    setCostError("no");
    try {
      const requestPayload = {
        importLocationID: Number(dispatchFirstData?.country),
        destinationZoneID: Number(dispatchFirstData?.dropOffZone),
        weight: Number(dispatchFirstData.weight),
      };

      await getImportCostPrice(requestPayload).unwrap();
    } catch (error) {
      const timer = setTimeout(() => {
        setCostError("Network Error! Please, try again!");
      }, 1000);
      return () => clearTimeout(timer);
    }
  };

  useEffect(() => {
    if (
      costData?.discountedRate === "0" ||
      costData?.discountedRate === 0 ||
      costIntlData?.rate === "0" ||
      costIntlData?.rate === 0
    ) {
      setCostError("Network Error! Please, try again!");
    }
  }, [costData, costIntlData]);

  useEffect(() => {
    if (isError || isIntlCostError) {
      const timer = setTimeout(() => {
        setCostError("Network Error! Please, try again!");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isError, isIntlCostError]);

  useEffect(() => {
    if (pickUpData) {
      setPickupZoneData(pickUpData.zones);
    }
  }, [pickUpData]);
  useEffect(() => {
    if (dropOffData) {
      setDropOffZoneData(dropOffData.zones);
    }
  }, [dropOffData]);

  const filteredOptions = pickUpZoneData.reduce((acc: any, zone: any) => {
    const filteredLocations = zone.location.filter((location: any) =>
      location.location.toLowerCase().includes(query.toLowerCase())
    );

    if (filteredLocations.length > 0) {
      acc.push({
        zone: zone.name,
        locations: filteredLocations,
      });
    }

    return acc;
  }, []);

  const filteredDropOffOptions = dropOffZoneData.reduce(
    (acc: any, zone: any) => {
      const filteredLocations = zone.location.filter((location: any) =>
        location.location.toLowerCase().includes(queryTwo.toLowerCase())
      );

      if (filteredLocations.length > 0) {
        acc.push({
          zone: zone.name,
          locations: filteredLocations,
        });
      }

      return acc;
    },
    []
  );

  const handleOptionClick = (option: any) => {
    if (option.location === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        pickUpZone: "Pickup zone is required",
      }));
    }
    if (option.location !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        pickUpZone: "",
      }));
    }
    setIsOpen(false);
    setQuery(option.location);
    setDispatchFirstData((prev) => ({
      ...prev,
      pickUpZone: option.zoneid,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      pickUpLocationId: option.id,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      pickUpLocationName: option.location,
    }));
  };

  const handleOptionClickTwo = (option: any) => {
    if (option.location === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "Drop off zone is required",
      }));
    }
    if (option.location !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "",
      }));
    }
    setQueryTwo(option.location);
    setIsOpenTwo(false);
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffLocationName: option.location,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffZone: option.zoneid,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffLocationId: option.id,
    }));
  };

  const toggleDropdown = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (
      costData?.discountedRate === "0:00" ||
      Number(costData?.discountedRate) === 0
    ) {
      const timer = setTimeout(() => {
        setCostError("Network Error! Please, try again!");
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [costData]);

  useEffect(() => {
    if (isOpenRequestType) {
      setIsOpenDeliveryType(false);
      setIsOpenTwo(false);
      setIsOpen(false);
    }
  }, [isOpenRequestType]);
  useEffect(() => {
    if (isOpen) {
      setIsOpenDeliveryType(false);
      setIsOpenTwo(false);
      setIsOpenCountry(false);
      setIsOpenRequestType(false);
    }
  }, [isOpen]);
  useEffect(() => {
    if (isOpenDeliveryType) {
      setIsOpen(false);
      setIsOpenTwo(false);
      setIsOpenCountry(false);

      setIsOpenRequestType(false);
    }
  }, [isOpenDeliveryType]);

  useEffect(() => {
    if (isOpenTwo) {
      setIsOpen(false);
      setIsOpenDeliveryType(false);
      setIsOpenRequestType(false);
      setIsOpenCountry(false);
    }
  }, [isOpenTwo]);
  useEffect(() => {
    if (isOpenCountry) {
      setIsOpen(false);
      setIsOpenDeliveryType(false);
      setIsOpenRequestType(false);
    }
  }, [isOpenCountry]);

  const handleCountryOption = (option: any) => {
    if (option === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        country: "Country is required",
      }));
    }
    if (option !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        country: "",
      }));
    }
    setIsOpenCountry(false);
    const selectedCountry = locationData.find(
      (location: any) => location?.country?.name === option
    );
    if (selectedCountry) {
      const { currency } = selectedCountry;

      setQueryCountry(option);
      setDispatchFirstData((prev) => ({
        ...prev,
        country: selectedCountry?.id,
      }));
      setCurrencyCode(currency?.code);
    } else {
      console.error(`Country '${option}' not found in locationData`);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setCost(costData?.discountedRate);
      setCloseModal(true);
    }
    if (isIntlSuccess) {
      setCost(costIntlData?.rate);
      setCloseModal(true);
    }
    if (isSendSuccess) {
      setCost(intlCostData?.discountedRate);
      setCloseModal(true);
    }
  }, [isSuccess, isSendSuccess, isIntlSuccess]);

  const onSubmit = () => {
    checkValidate();
    if (dispatchFirstData.country === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        country: "Country is required",
      }));
    } else {
      setInfoErrorMessages((prev) => ({
        ...prev,
        country: "",
      }));
    }
    if (
      dispatchFirstData.pickUpZone !== "" &&
      dispatchFirstData.dropOffZone !== "" &&
      dispatchFirstData.deliveryType !== "" &&
      dispatchFirstData.weight !== "" &&
      infoErrorMessage === ""
    ) {
      getCostPriceData();
    }
    if (
      dispatchFirstData.country !== "" &&
      dispatchFirstData.dropOffZone !== "" &&
      dispatchFirstData.deliveryType !== "" &&
      dispatchFirstData.weight !== ""
    ) {
      getIntlCostPriceData();
    }
  };
  const handleCountryOptionTwo = (option: any) => {
    console.log("wee");
    if (option === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        country: "Country is required",
      }));
    }
    if (option !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        country: "",
      }));
    }
    const selectedCountry = locationData.find(
      (location: any) => location?.country?.name === option
    );
    if (selectedCountry) {
      const { country, currency } = selectedCountry;
      setDispatchFirstData((prev) => ({
        ...prev,
        importLocationName: country?.name,
      }));
      setDispatchFirstData((prev) => ({
        ...prev,
        country: selectedCountry?.id,
      }));
      setDispatchFirstData((prev) => ({
        ...prev,
        importAddress: selectedCountry.proof,
      }));
      setQueryThree(country?.name);
      setIsOpenTwo(false);
      setShowAddresses(selectedCountry);
      setCurrencyCode(currency?.code);
    } else {
      console.error(`Country '${option}' not found in locationData`);
    }
  };
  const handleCopy = (address: string) => {
    setCopiedAdd(address);
    navigator.clipboard
      .writeText(address)
      .then(() => {
        setShowClipboard(true);

        setTimeout(() => {
          setShowClipboard(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Unable to copy to clipboard", err);
      });
  };
  const handleWeightType = (e: any) => {
    if (e.id) {
      setInfoErrorMessages((prev) => ({ ...prev, weight: "" }));
    }
    setWeight({
      id: e.id,
      name: e.name,
    });
    setDispatchFirstData((prev) => ({
      ...prev,
      weight: e.id,
    }));
    setIsOpenWeight(false);
  };
  const handleIntlOption = (option: any) => {
    if (option === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "Drop off zone is required",
      }));
    }
    if (option !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "",
      }));
    }

    setQueryTwo(option?.name);
    setIsOpenTwo(false);
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffLocationName: option?.name,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffZone: option.id,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffLocationId: option.id,
    }));
  };

  const filteredIntLocations = Array.isArray(intlZonesData?.zones)
    ? queryTwo !== ""
      ? intlZonesData.zones.filter((location: any) =>
          location?.name.toLowerCase().includes(queryTwo?.toLowerCase())
        )
      : intlZonesData.zones
    : [];

  const focusHandleDropOff = () => {
    if (queryTwo === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "Drop off zone is required",
      }));
    }
    if (queryTwo !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "",
      }));
    }
  };
  const getIntlCostData = async () => {
    if (dispatchFirstData.dropOffLocationId === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "Drop off zone is required",
      }));
    } else {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "",
      }));
    }

    try {
      const requestPayload = {
        zone: dispatchFirstData.dropOffLocationId,
        weight: dispatchFirstData.weight,
      };

      await getIntlCost(requestPayload).unwrap();
    } catch (error) {
      setCostError("Network Error! Please, try again!");
    }
  };
  return (
    <PageLayout>
      <div className=" mb-32 ">
        <div className=" bg-[#EFF6EE] text-center flex items-center justify-center flex-col px-5 sm:px-10 xl:px-20 mb-5 min-h-[700px] pt-10 -mt-32">
          <h2 className="xl:text-[90px] pb-0  text-center font-ppmigra lg:text-[70px] text-[35px] sm:text-[50px] md:text-[70px] whitespace-nowrap text-textColor font-bold">
            Get delivery estimate
          </h2>
          <p className="">Need a quick quote? We’ve got you covered</p>
        </div>
        <div className="flex justify-center -mt-[200px]">
          <div className="max-w-[800px] w-full px-8 sm:px-24 md:px-32 py-10 bg-white">
            <h4 className="text-textColor py-7 font-onestBold font-[700] text-[20px]">
              Enter the following details
            </h4>

            <div className="space-y-5">
              <DropDownSelect
                lists={[
                  {
                    id: 1,
                    name: "Local deliveries",
                  },
                  {
                    id: 2,
                    name: "Send to Nigeria",
                  },
                  {
                    id: 3,
                    name: "Send from Nigeria",
                  },
                ]}
                toggleDropdown={() =>
                  setIsOpenDeliveryType(!isOpenDeliveryType)
                }
                handleOptionClick={(e: any) => {
                  if (e.name.toLowerCase() === "local deliveries") {
                    setQueryDeliveryValue("Local");
                  } else {
                    setQueryDeliveryValue(e?.name);
                  }
                  setIsOpenDeliveryType(!isOpenDeliveryType);
                }}
                isOpen={isOpenDeliveryType}
                setIsOpen={setIsOpenDeliveryType}
                setQuery={setQueryDeliveryType}
                query={queryDeliveryValue==="Local"?"Local deliveries":queryDeliveryValue}
                name="deliveryType"
                title="Select Delivery Type"
                label={"Delivery type"}
                zindex="!z-[150]"
              />
              {queryDeliveryValue.toLowerCase() === "local" && (
                <div className="flex flex-col w-full">
                  <label className="text-[#000000] items-center mb-2 flex text-[14px]  font-onestMedium">
                    Delivery mode
                  </label>
                  <div
                    className={` p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border-2 border-inputBorderGreen  focus:border-2  focus:border-border-2 focus:border-inputBorderGreen rounded-[8px] bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full`}
                  >
                    <p className="text-textColor">Bike</p>
                  </div>
                </div>
              )}
              {queryDeliveryValue.toLowerCase() !== "send from nigeria" ? (
                <div>
                  <Input
                    type="text"
                    label="Weight"
                    placeholder="0"
                    className="w-full"
                    weightShow
                    name="weight"
                    value={dispatchFirstData.weight}
                    onChange={handleChange}
                  />
                  {infoErrorMessages.weight && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.weight}
                    </p>
                  )}
                </div>
              ) : (
                <div>
                  <DropDownSelect
                    placeholder="Select Weight"
                    lists={intlWeightData?.weight}
                    toggleDropdown={() => setIsOpenWeight(!isOpenWeight)}
                    isRequired
                    handleOptionClick={handleWeightType}
                    isOpen={isOpenWeight}
                    setIsOpen={setIsOpenDeliveryType}
                    setQuery={setQueryDeliveryType}
                    query={weight.name}
                    name="weight"
                    title="Select Weight"
                    label={"weight type"}
                  />
                  {infoErrorMessages.weight && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.weight}
                    </p>
                  )}
                </div>
              )}

              {queryDeliveryValue.toLowerCase() !== "send from nigeria" && (
                <>
                  {queryDeliveryValue.toLowerCase() === "local" ? (
                    <div>
                      <SearchableDropdown
                        name="pickUpZone"
                        lists={filteredOptions}
                        toggleDropdown={toggleDropdown}
                        handleOptionClick={handleOptionClick}
                        title="Select pickup zone"
                        label={"Pick-up Zone"}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        setQuery={setQuery}
                        query={query}
                        setZoneOption={setZoneOption}
                      />
                      {infoErrorMessage?.includes("pickUpZone") && (
                        <p className="text-red-500 text-[12px]">
                          Pick up zone is required
                        </p>
                      )}
                      {infoErrorMessages.pickUpZone && (
                        <p className="text-red-500 text-[12px]">
                          {infoErrorMessages.pickUpZone}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div>
                      <SelectInput
                        value={queryCountry}
                        name="country"
                        placeholder={"Country"}
                        handleChange={(name, value) =>
                          handleCountryOption(value)
                        }
                        label="Select country"
                        dropdownOptions={locationData.map((location: any) => ({
                          label: location?.country?.name,
                          value: location?.country?.name,
                        }))}
                      />
                      {infoErrorMessages.country && (
                        <p className="text-red-500 text-[12px]">
                          {infoErrorMessages.country}
                        </p>
                      )}
                    </div>
                  )}
                </>
              )}
              <div>
                {queryDeliveryValue.toLowerCase() !== "send from nigeria" ? (
                  <SearchableDropdown
                    lists={filteredDropOffOptions}
                    handleOptionClick={handleOptionClickTwo}
                    title="Select drop-off zone"
                    label={"Pick-up Zone"}
                    isOpen={isOpenTwo}
                    name="dropOffZone"
                    setIsOpen={setIsOpenTwo}
                    setQuery={setQueryTwo}
                    query={queryTwo}
                    setZoneOption={setZoneOptionTwo}
                    toggleDropdown={() => setIsOpenTwo(!isOpenTwo)}
                  />
                ) : (
                  <SearchableDropdown
                    intlLists={filteredIntLocations}
                    handleOptionClick={handleIntlOption}
                    onFocus={focusHandleDropOff}
                    title="Select drop-off zone"
                    label={"Drop-off Zone"}
                    isOpen={isOpenTwo}
                    name="dropOffZone"
                    setIsOpen={setIsOpenTwo}
                    setQuery={setQueryTwo}
                    weight={dispatchFirstData.weight}
                    query={queryTwo}
                    isRequired
                    errorList={dispatchFirstData.dropOffZone}
                    setZoneOption={setZoneOptionTwo}
                    toggleDropdown={() => setIsOpenTwo(!isOpenTwo)}
                  />
                )}

                {infoErrorMessage?.includes("pickUpZone") && (
                  <p className="text-red-500 text-[12px]">
                    Drop off zone is required
                  </p>
                )}
                {infoErrorMessages.dropOffZone && (
                  <p className="text-red-500 text-[12px]">
                    {infoErrorMessages.dropOffZone}
                  </p>
                )}
              </div>
            </div>
            <div className="mt-14 ">
              {queryDeliveryValue.toLowerCase() !== "send from nigeria" ? (
                <Button
                  title="Submit"
                  className="!py-3"
                  loading={isLoading || isIntlCostLoading}
                  onClick={onSubmit}
                />
              ) : (
                <Button
                  title="Submit"
                  className="!py-3"
                  loading={isLoadingIntlCost}
                  onClick={getIntlCostData}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {closeModal && (
        <DashboardModalAction
          contentTitle={"Your delivery estimate"}
          content={`Your delivery estimate is`}
          btnContent={`Close`}
          imgStr={"/images/money-box.svg"}
          amount={cost}
          deliveryRoute={"Ship Now"}
          deliveryRouteOnclick={() => {
            if (queryDeliveryValue.toLowerCase() === "local") {
              navigate("/ship-now?type=local");
            } else if (
              queryDeliveryValue.toLowerCase() === "send from nigeria"
            ) {
              navigate("/ship-now");
            } else {
              navigate("/import");
            }
          }}
          currency={
            queryDeliveryValue.toLowerCase() === "send to nigeria"
              ? getSymbolFromCurrency(currencyCode)
              : "₦"
          }
          onClick={() => {
            location.reload();
            setDispatchFirstData({
              requestType: "",
              pickUpZone: "",
              dropOffZone: "",
              deliveryType: "local",
              deliveryMode: "bike",
              weight: "",
              country: "",
              dropOffLocationId: "",
              dropOffLocationName: "",
              pickUpLocationId: "",
            });
            setInfoErrorMessages({
              pickUpZone: "",
              dropOffZone: "",
              weight: "",
              country: "",
            });
            setQuery("");
            setQueryTwo("");
            setCloseModal(false);
          }}
        />
      )}
      {costError !== "no" && (
        <ErrorModal
          errorMessage={costError}
          onClick={() => location.reload()}
        />
      )}
    </PageLayout>
  );
};

export default DeliveryEstimate;
