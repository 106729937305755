/* eslint-disable */
import { useEffect, useState } from "react";
import {
  DropDownSelect,
  SearchableDropdown,
} from "../components/form/Dropdown";
import { Input } from "../components/form/Input";
import { Button } from "../components/Button";
import { TextArea } from "../components/form/textArea";
import { SectionBack } from "../components/SectionBack";
import { PageLayout } from "../components/Layout";
import {
  useGetDropOffZonesQuery,
  useGetIntlZonesMutation,
  useGetPickUpZonesQuery,
} from "../services/slices/getZonesSlice";
import DatePicker from "react-datepicker";
import { usePaystackPayment } from "react-paystack";
import {
  useGetCostPriceMutation,
  useGetIntlCostPriceMutation,
  useGetIntlReferralPriceMutation,
  useGetIntlWeightQuery,
} from "../services/slices/costSlice";
import {
  useOrderMultipleMutation,
  useRemoveOrderMutation,
  useUpdateReferenceMutation,
} from "../services/slices/multiple";
import {
  checkDateRange,
  generateRandomDataWithDelay,
  scrollToTop,
  validateEmail,
} from "../helper";
import {
  DashboardModalAction,
  ExportInfoModal,
  ShowErrorandYS,
  ShowOrderErrorandD,
  ShowOrderLockYF,
  ShowTermsModal,
} from "../components/Modal";
import { InformationLayout } from "../components/InfoLayout";
import { object, string, number } from "yup";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { useGetReferalStatusMutation } from "../services/slices/referralSlice";
import { useGetTermsQuery } from "../services/slices/terms";
import { useSearchParams } from "react-router-dom";
import {
  useGetLockerAvailabilityMutation,
  useGetLockersMutation,
} from "../services/slices/lockers";
import SelectInput from "../components/form/SelectInput";

const DispatchNow = () => {
  const [step, setStep] = useState(1);
  const [pickUpZoneData, setPickupZoneData] = useState([]);
  const [dropOffZoneData, setDropOffZoneData] = useState([]);
  const [costDataResult, setCostDataResult] = useState<any>([]);
  const [zoneOption, setZoneOption] = useState<any>(null);
  const [zoneOptionTwo, setZoneOptionTwo] = useState<any>(null);
  const [query, setQuery] = useState("");
  const [clientUserId, setClientUserId] = useState("");
  const [clientUserPhone, setClientUserPhone] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [applyReferral, setApplyReferral] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState<boolean>(false);
  const [applyReferralTwo, setApplyReferralTwo] = useState(false);
  const [queryTwo, setQueryTwo] = useState("");
  const [queryDeliverytype, setQueryDeliveryType] = useState("");
  const [costError, setCostError] = useState("no");
  const [referenceId, setReferenceId] = useState("");
  const [referenceIdTwo, setReferenceIdTwo] = useState("");
  const [weight, setWeight] = useState({
    id: null,
    name: "",
  });
  const [startDate, setStartDate] = useState(null);
  const [isOpenTwo, setIsOpenTwo] = useState(false);
  const [isOpenWeight, setIsOpenWeight] = useState(false);
  const [showOrderError, setShowOrderError] = useState(false);
  const [showOrderErrorOne, setShowOrderErrorOne] = useState(false);
  const [regenerate, setRegenerate] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showOrderErrorThree, setShowOrderErrorThree] = useState(false);
  const [errorCost, setErrorCost] = useState("");
  const [payAmount, setPayAmount] = useState("");
  const [dropOffState, setDropOffState] = useState("");
  const [pickUpState, setPickUpState] = useState("");

  const [infoErrorMessages, setInfoErrorMessages] = useState({
    pickUpAddress: "",
    pickUpZone: "",
    pickUpLocationId: "",
    pickUpLocationName: "",
    dropOffAddress: "",
    dropOffZone: "",
    dropOffLocationId: "",
    dropOffLocationName: "",
    pickUpDate: "",
    senderName: "",
    senderPhone: "",
    senderAltPhone: "",
    senderEmail: "",
    recipientName: "",
    recipientEmail: "",
    recipientPhone: "",
    itemDescription: "",
    quantity: "",
    referenceId: "",
    weight: "",
    cost: "",
    additionalNote: "",
    dropOffLocker: "",
  });
  const [isOpenDeliveryType, setIsOpenDeliveryType] = useState(false);
  const [useSmartLocker, setUseSmartLocker] = useState(false);
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);
  const [orderStatus, setOrderStatus] = useState(true);
  const { data: pickUpData } = useGetPickUpZonesQuery();
  const { data: dropOffData } = useGetDropOffZonesQuery();
  const { data: termsAndCondition } = useGetTermsQuery();
  const [
    getCostPrice,
    { data: costData, isLoading: isCostLoading, isError: isCostError },
  ] = useGetCostPriceMutation();
  const [
    getLockers,
    { data: lockerData, isLoading: isLockerLoading, isError: isLockerError },
  ] = useGetLockersMutation();
  const [
    getLockersAvailability,
    {
      data: lockerAvailabilityData,
      isLoading: isLockerAvailabilityLoading,
      isError: isLockerAvailabilityError,
    },
  ] = useGetLockerAvailabilityMutation();
  const [orderMultiple, { data: multipleData, isLoading: isMultipleLoading }] =
    useOrderMultipleMutation();
  const [getIntlCost, { data: intlCostData, isLoading: isLoadingIntlCost }] =
    useGetIntlCostPriceMutation();
  const [
    deleteOrder,
    { data: deleteData, error: deleteDataError, isLoading: deleteLoading },
  ] = useRemoveOrderMutation();
  const [updateReference] = useUpdateReferenceMutation();
  const [
    getIntlReferralCost,
    { data: intlReferralData, isLoading: isLoadingReferralCost },
  ] = useGetIntlReferralPriceMutation();
  const { data: intlWeightData } = useGetIntlWeightQuery();
  const [getIntlZones, { data: intlZonesData, isLoading: isLoadingIntlZones }] =
    useGetIntlZonesMutation();
  const [
    getReferralCode,
    { data: referralData, isLoading: isReferralLoading },
  ] = useGetReferalStatusMutation();
  const [params] = useSearchParams();
  const deliveryType = params?.get("type");
  interface Props {
    requestType: string;
    clientAddress: string;
    recipientAddress: string;
    recipientName: string;
    recipientPhone: string;
    recipientEmail: string;
    quantity: number;
    weight: number;
    itemDescription: string;
    orderDate: any;
    cost: string;
    discount: string;
    discountedCost: string;
    paymentStatus: string;
    paymentReference: string;
    additionalNote: string;
    thirdparty: string;
    thirdparty_sendersName: string;
    thirdparty_sendersPhone: string;
    thirdparty_senders_Alt_Phone: string;
    clientEmail: string;
    zoneID: number;
    ZoneName: string;
    locationID: number;
    locationName: string;
    dropzoneID: number;
    dropZoneName: string;
    droplocationID: number;
    droplocationName: string;
    transportMode: string;
    deliveryType: string;
    valueOfItem: number;
    expanded: boolean;
    clientName: string;
    clientPhone: string;
    referralCode?: string;
    dropOffLocker?: string;
    lockerID?: string;
    source: string;
    dropOffLockerAddress?: string;
  }
  interface CostProps {
    ori_zone: string;
    des_zone: string;
    deliveryType: string;
    weight: number;
    locker?: boolean;
  }

  interface RefProps {
    orderNo: string;
    reference?: string;
    referralCode?: string;
  }

  const [dispatchFirstData, setDispatchFirstData] = useState({
    pickUpAddress: "",
    pickUpZone: "",
    pickUpLocationId: "",
    pickUpLocationName: "",
    dropOffAddress: "",
    dropOffZone: "",
    dropOffLocationId: "",
    dropOffLocationName: "",
    deliveryType: "bike",
    deliveryMode: "bike",
    pickUpDate: "",
    senderName: "",
    senderPhone: "",
    senderAltPhone: "",
    senderEmail: "",
    recipientEmail: "",
    recipientName: "",
    recipientPhone: "",
    itemDescription: "",
    quantity: "",
    referenceId: "",
    weight: "",
    cost: "",
    additionalNote: "",
    referralCode: "",
    dropOffLocker: "",
    dropOffLockerAddress: "",
  });

  const userDetails: any = localStorage.getItem("fez-user");

  let userSchema = object({
    dropOffZone: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            dropOffZone: "Dropoff  zone  is required",
          }));
          throw new TypeError("Dropoff  zone  is required");
        }
        return value;
      })
      .required("Dropoff  zone  is required"),
    pickUpZone: string()
      .transform((value, originalValue) => {
        if (originalValue === "" && query === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            pickUpZone: "Pickup zone  is required",
          }));
          throw new TypeError("Pickup zone  is required");
        }
        return value;
      })
      .required("Pickup zone  is required"),
    pickUpAddress: string().transform((value, originalValue) => {
      if (originalValue === "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          pickUpAddress: "Pick up address is required",
        }));
        // setInfoErrorMessage("Pick up address is required");
        throw new TypeError("Pick up address is required");
      }
      return value;
    }),
    // .required("Pick up address is required"),
    dropOffAddress: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            dropOffAddress: "Drop off address is required",
          }));
          throw new TypeError("Drop off address is required");
        }
        return value;
      })
      .required("Drop off address is required"),
    pickUpDate: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            pickUpDate: "Pick up date is required",
          }));
        }
        return value;
      })
      .required("Pick up date is required"),
    weight: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            weight: "weight must be a valid number",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            weight: "weight is required",
          }));

          throw new TypeError("weight must be a valid number");
        }

        return value;
      })
      .required("weight must be a number")
      .positive()
      .integer(""),
  });

  let userSchemaTwo = object({
    senderName: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderName: "Sender name is required",
          }));
          throw new TypeError("Sender name is required");
        }
        return value;
      })
      .required("Sender name is required"),
    recipientName: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            recipientName: "Recipient name is required",
          }));
          throw new TypeError("Recipient name is required");
        }
        return value;
      })
      .required("Recipient name is required"),
    itemDescription: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            itemDescription: "Item description is required",
          }));
          throw new TypeError("Item description is required");
        }
        return value;
      })
      .required("Item description is required"),

    senderPhone: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderPhone: "phone number must be a valid number",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderPhone: "phone number is required",
          }));
          throw new TypeError("phone number must be a valid number");
        }
        return value;
      })
      .required("phone number must be a number")
      .positive()
      .integer(""),
    senderAltPhone: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderAltPhone: "phone number must be a valid number",
          }));
        }
        if (!isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderAltPhone: "",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderAltPhone: "",
          }));
        }
        return value;
      })
      .positive()
      .integer(""),
    recipientPhone: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            recipientPhone: "phone number must be a valid number",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            recipientPhone: "phone number is required",
          }));
          throw new TypeError("phone number must be a valid number");
        }
        return value;
      })
      .required("phone number must be a number")
      .positive()
      .integer(""),
    quantity: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            quantity: "quantity must be a valid number",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            quantity: "quantity is required",
          }));
          throw new TypeError("quantity must be a valid number");
        }
        return value;
      })
      .required("quantity must be a number")
      .positive()
      .integer(""),
    cost: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            cost: "cost must be a valid number",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            cost: "cost is required",
          }));
          throw new TypeError("cost must be a valid number");
        }
        return value;
      })
      .required("cost must be a number")
      .positive()
      .integer(""),
    senderEmail: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderEmail: "Email is required",
          }));
          throw new TypeError("Email is required");
        }
        return value;
      })
      .email("Invalid email format")
      .required("Email is required"),
  });

  const checkValidate = async () => {
    try {
      await userSchema.validate(dispatchFirstData);
    } catch (err: any) {
      const errorMessage = err?.errors?.join(", ");
    }
  };

  useEffect(() => {
    if (dispatchFirstData?.dropOffLockerAddress === "" && useSmartLocker) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffLocker: "Select a drop off locker",
      }));
    } else {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffLocker: "",
      }));
    }
  }, [useSmartLocker, dispatchFirstData?.dropOffLockerAddress]);
  const checkValidateTwo = async () => {
    try {
      await userSchemaTwo.validate(dispatchFirstData);
    } catch (err: any) {
      const errorMessage = err?.errors?.join(", ");
    }
  };

  useEffect(() => {
    if (
      dispatchFirstData?.senderEmail !== "" &&
      validateEmail(dispatchFirstData?.senderEmail) === null
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        senderEmail: "Invalid email format",
      }));
    } else if (
      dispatchFirstData?.senderEmail !== "" &&
      validateEmail(dispatchFirstData?.senderEmail) !== null
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        senderEmail: "",
      }));
    }
  }, [dispatchFirstData?.senderEmail]);

  useEffect(() => {
    if (
      dispatchFirstData?.recipientEmail !== "" &&
      validateEmail(dispatchFirstData?.recipientEmail) === null
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        recipientEmail: "Invalid email format",
      }));
    } else if (
      dispatchFirstData?.recipientEmail !== "" &&
      validateEmail(dispatchFirstData?.recipientEmail) !== null
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        recipientEmail: "",
      }));
    }
  }, [dispatchFirstData?.recipientEmail]);

  useEffect(() => {
    if (userDetails) {
      const { clientID, clientsPhone } = JSON.parse(userDetails);
      setClientUserId(clientID);
      setClientUserPhone(clientsPhone);
    }
  }, [userDetails]);

  const getCostPriceData = async () => {
    setErrorCost("");
    setCostError("no");
    setOrderStatus(true);
    try {
      const requestPayload: CostProps = {
        ori_zone: dispatchFirstData.pickUpZone,
        des_zone: dispatchFirstData.dropOffZone,
        deliveryType: dispatchFirstData.deliveryType,
        weight: Number(dispatchFirstData.weight),
      };
      if (lockerAvailabilityData?.Available && useSmartLocker) {
        requestPayload.locker = true;
      }
      await getCostPrice(requestPayload).unwrap();
    } catch (error) {
      setCostError("Network Error! Please, try again!");
    }
  };

  useEffect(() => {
    if (queryDeliverytype.toLowerCase() !== "local") {
      getIntlZones(weight?.id);
    }
  }, [queryDeliverytype, weight]);

  const getMultipleData = async () => {
    try {
      const requestPayload: Props = {
        requestType:
          queryDeliverytype.toLowerCase() === "local" ? "local" : "export",
        clientAddress: dispatchFirstData.pickUpAddress,
        recipientAddress:
          dispatchFirstData.dropOffAddress !== ""
            ? dispatchFirstData.dropOffAddress
            : dispatchFirstData.dropOffLockerAddress,
        recipientEmail: dispatchFirstData.recipientEmail,
        recipientName: dispatchFirstData.recipientName,
        recipientPhone: dispatchFirstData.recipientPhone,
        quantity: Number(dispatchFirstData.quantity),
        itemDescription: dispatchFirstData.itemDescription,
        orderDate: dispatchFirstData.pickUpDate,
        cost:
          queryDeliverytype.toLowerCase() === "local"
            ? costData?.discountedRate
            : intlCostData?.discountedRate,
        weight: Number(dispatchFirstData.weight),
        discount:
          queryDeliverytype.toLowerCase() === "local"
            ? costData?.discount
            : intlCostData?.discount,
        discountedCost:
          queryDeliverytype.toLowerCase() === "local"
            ? referralData?.discountedRate !== undefined &&
              referralData?.discountedRate !== ""
              ? referralData.discountedRate
              : costData?.discountedRate
            : intlReferralData?.discountedRate !== undefined &&
              intlReferralData?.discountedRate !== ""
            ? intlReferralData.discountedRate
            : intlCostData?.discountedRate,
        paymentStatus: "ppc",
        paymentReference: referenceId,
        additionalNote: dispatchFirstData.additionalNote,
        thirdparty: "true",
        thirdparty_sendersName: dispatchFirstData.senderName,
        thirdparty_sendersPhone: dispatchFirstData.senderPhone,
        clientEmail: dispatchFirstData.senderEmail,
        thirdparty_senders_Alt_Phone: dispatchFirstData.senderAltPhone,
        zoneID:
          queryDeliverytype.toLowerCase() === "local"
            ? Number(dispatchFirstData.pickUpZone)
            : 1,
        ZoneName:
          queryDeliverytype.toLowerCase() === "local" ? zoneOption : "Lagos",
        locationID:
          queryDeliverytype.toLowerCase() === "local"
            ? Number(dispatchFirstData.pickUpLocationId)
            : 1,
        locationName:
          queryDeliverytype.toLowerCase() === "local"
            ? dispatchFirstData.pickUpLocationName
            : "Lagos",
        dropzoneID: Number(dispatchFirstData.dropOffZone),
        dropZoneName: zoneOptionTwo,
        droplocationID: Number(dispatchFirstData.dropOffLocationId),
        droplocationName: dispatchFirstData.dropOffLocationName,
        transportMode: "Bike",
        deliveryType: dispatchFirstData.deliveryType,
        valueOfItem: Number(dispatchFirstData.cost),
        expanded: false,
        clientName: clientUserId,
        clientPhone: clientUserPhone,
        source: "Fez Website",
      };
      if (referralData?.status === "00" || intlReferralData?.status === "00") {
        requestPayload.referralCode = dispatchFirstData?.referralCode;
      }
      if (
        lockerAvailabilityData?.Available &&
        dispatchFirstData?.dropOffLocker !== "" &&
        useSmartLocker
      ) {
        requestPayload.lockerID = dispatchFirstData?.dropOffLocker;
      }
      const response = await orderMultiple(requestPayload).unwrap();
      localStorage.setItem("orderNo", response?.orderNos[0]);
      if (response.status !== "Error") {
        initializePayment(onSuccess, onClose);
      }
    } catch (error) {
      setCostError("Network Error! Please, try again!");
    }
  };

  useEffect(() => {
    if (pickUpData) {
      setPickupZoneData(pickUpData.zones);
    }
  }, [pickUpData]);

  useEffect(() => {
    if (dropOffData && queryDeliverytype.toLowerCase() === "local") {
      setDropOffZoneData(dropOffData.zones);
    }
    if (dropOffData && queryDeliverytype.toLowerCase() !== "local") {
      setDropOffZoneData([]);
      setDispatchFirstData((prev) => ({
        ...prev,
        dropOffZone: "",
      }));
    }
  }, [dropOffData, queryDeliverytype]);

  useEffect(() => {
    if (isCostError) {
      setErrorCost("Network Error! Please, try again!");
    }
  }, [isCostError]);

  useEffect(() => {
    if (queryDeliverytype.toLowerCase() === "local") {
      if (referralData && referralData?.discountedRate !== "") {
        setPayAmount(referralData?.discountedRate);
      } else {
        setPayAmount(costData?.discountedRate);
      }
    } else {
      if (intlReferralData && intlReferralData?.discountedRate !== "") {
        setPayAmount(intlReferralData?.discountedRate);
      } else {
        setPayAmount(intlCostData?.discountedRate);
      }
    }
  }, [
    referralData?.discountedRate,
    intlCostData?.discountedRate,
    costData?.discountedRate,
    intlReferralData?.discountedRate,
  ]);

  const config = {
    reference: referenceIdTwo,
    email: dispatchFirstData?.senderEmail,
    amount: Number(payAmount) * 100,
    publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
  };

  const initializePayment = usePaystackPayment(config);

  const filteredOptions = pickUpZoneData.reduce((acc: any, zone: any) => {
    const filteredLocations = zone.location.filter((location: any) =>
      location.location.toLowerCase().includes(query.toLowerCase())
    );

    if (filteredLocations.length > 0) {
      acc.push({
        zone: zone.name,
        locations: filteredLocations,
        state: zone.state_id === null ? zone.hub?.state_id : zone.state_id,
      });
    }
    return acc;
  }, []);

  const filteredDropOffOptions = dropOffZoneData.reduce(
    (acc: any, zone: any) => {
      const filteredLocations = zone.location.filter((location: any) =>
        location.location.toLowerCase().includes(queryTwo?.toLowerCase())
      );

      if (filteredLocations.length > 0) {
        acc.push({
          zone: zone.name,
          locations: filteredLocations,
          state: zone.state_id === null ? zone.hub?.state_id : zone.state_id,
        });
      }
      return acc;
    },
    []
  );

  const filteredIntLocations = Array.isArray(intlZonesData?.zones)
    ? queryTwo !== ""
      ? intlZonesData.zones.filter((location: any) =>
          location?.name.toLowerCase().includes(queryTwo?.toLowerCase())
        )
      : intlZonesData.zones
    : [];

  useEffect(() => {
    if (queryDeliverytype.toLowerCase() !== "local") {
      setQuery("Lagos");
      setInfoErrorMessages((prev) => ({
        ...prev,
        pickUpZone: "",
      }));
      setDispatchFirstData((prev) => ({
        ...prev,
        pickUpZone: "1",
      }));
    } else {
      setQuery("");
      setDispatchFirstData((prev) => ({
        ...prev,
        pickUpZone: "",
      }));
    }
  }, [queryDeliverytype]);

  const handleOptionClick = (option: any, state: any) => {
    if (option.location === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        pickUpZone: "Pickup zone is required",
      }));
    }
    if (option.location !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        pickUpZone: "",
      }));
    }

    setPickUpState(state);
    setQuery(option.location);
    setIsOpen(false);
    setDispatchFirstData((prev) => ({
      ...prev,
      pickUpZone: option.zoneid,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      pickUpLocationId: option.id,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      pickUpLocationName: option.location,
    }));
  };

  const handleOptionClickTwo = (option: any, state: any) => {
    if (option.location === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "Drop off zone is required",
      }));
    }
    if (option.location !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "",
      }));
    }

    setDropOffState(state);
    setQueryTwo(option.location);
    setIsOpenTwo(false);
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffLocationName: option.location,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffZone: option.zoneid,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffLocationId: option.id,
    }));
  };

  const handleIntlOption = (option: any) => {
    if (option === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "Drop off zone is required",
      }));
    }
    if (option !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "",
      }));
    }
    setQueryTwo(option?.name);
    setIsOpenTwo(false);
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffLocationName: option?.name,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffZone: option.id,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffLocationId: option.id,
    }));
  };

  useEffect(() => {
    const getSmartLockerLocation = async () => {
      if (
        dispatchFirstData?.dropOffZone &&
        queryDeliverytype?.toLowerCase() === "local"
      ) {
        const body = {
          zone: dispatchFirstData?.dropOffZone,
        };
        await getLockers(body).unwrap();
      }
    };
    getSmartLockerLocation();
  }, [dispatchFirstData?.dropOffZone, queryDeliverytype, useSmartLocker]);

  const focusHandlePickUp = () => {
    if (query === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        pickUpZone: "Pick up zone is required",
      }));
    }
    if (query !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        pickUpZone: "",
      }));
    }
  };

  const focusHandleDropOff = () => {
    if (queryTwo === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "Drop off zone is required",
      }));
    }
    if (queryTwo !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "",
      }));
    }
  };

  const handleWeightType = (e: any) => {
    if (e.id) {
      setInfoErrorMessages((prev) => ({ ...prev, weight: "" }));
    }
    setWeight({
      id: e.id,
      name: e.name,
    });
    setDispatchFirstData((prev) => ({
      ...prev,
      weight: e.name,
    }));
    setIsOpenWeight(false);
  };

  const handleChange = async (evt: any) => {
    const { name, value } = evt.target;

    setDispatchFirstData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "weight" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, weight: "" }));
    }
    if (name === "weight" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, weight: "" }));
    }
    if (name === "pickUpAddress" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, pickUpAddress: "" }));
    }
    if (name === "dropOffAddress" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, dropOffAddress: "" }));
      setDispatchFirstData((prev) => ({
        ...prev,
        dropOffLocker: "",
      }));
    }

    try {
      await userSchema.validateAt(name, { [name]: value });
    } catch (error: any) {
      if (error) {
        // setInfoErrorMessage(error.message);
      }
    }
  };

  const handleChangeTwo = async (evt: any) => {
    const { name, value } = evt.target;

    setDispatchFirstData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "senderPhone" && value !== "" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, senderPhone: "" }));
    }
    if (name === "senderAltPhone" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, senderPhone: "" }));
    }
    if (name === "senderName" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, senderName: "" }));
    }
    if (name === "senderEmail" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, senderEmail: "" }));
    }
    if (name === "recipientName" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, recipientName: "" }));
    }
    if (name === "recipientPhone" && value !== "" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, recipientPhone: "" }));
    }
    if (name === "quantity" && value !== "" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, quantity: "" }));
    }
    if (name === "cost" && value !== "" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, cost: "" }));
    }
    if (name === "itemDescription" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, itemDescription: "" }));
    }
    try {
      await userSchemaTwo.validateAt(name, { [name]: value });
    } catch (error: any) {
      if (error) {
        // setInfoErrorMessage(error.message);
      }
    }
  };

  useEffect(() => {
    if (queryDeliverytype.toLowerCase() !== "local" && startDate !== "") {
      const isInRange = checkDateRange(startDate);
      if (isInRange) {
        setShowPriceModal(true);
      } else {
        setShowPriceModal(false);
      }
    }
  }, [startDate, queryDeliverytype]);

  const onChangeDate = async (selectedDate: any) => {
    setStartDate(selectedDate);

    if (selectedDate !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, pickUpDate: "" }));
    }
    if (selectedDate !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, pickUpDate: "" }));
    }
    setDispatchFirstData((prev) => ({
      ...prev,
      pickUpDate: moment(selectedDate).format("YYYY-MM-DD HH:mm:ss"),
    }));

    try {
      await userSchema.validateAt("pickUpDate", { pickUpDate: selectedDate });
    } catch (error: any) {
      setInfoErrorMessages(error.message);
    }
  };

  const onFocusDate = () => {
    if (startDate === null) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        pickUpDate: "Pick up date is required!",
      }));
    }
    if (startDate !== null) {
      setInfoErrorMessages((prev) => ({ ...prev, pickUpDate: "" }));
    }
  };

  useEffect(() => {
    if (step === 2 || step === 3) {
      scrollToTop();
    }
  }, [step]);

  const onGenerate = () => {
    const response = generateRandomDataWithDelay();

    if (clientUserId) {
      setReferenceId(`c_${response}_${clientUserId}_OP`);
      setReferenceIdTwo(`${response}_${clientUserId}_OP`);
    }
  };


  const onFinishStepOne = async () => {
    if (dispatchFirstData?.dropOffLockerAddress === "" && useSmartLocker) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffLocker: "Select a drop off address",
      }));
    } else if (
      dispatchFirstData?.dropOffLockerAddress !== "" &&
      useSmartLocker
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffLocker: "",
      }));
    }
    if (dispatchFirstData?.pickUpZone !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        pickUpZone: "",
      }));
    } else if (dispatchFirstData?.pickUpZone === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        pickUpZone: "Pick up zone is required",
      }));
    }

    if (dispatchFirstData?.dropOffZone !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "",
      }));
    } else if (dispatchFirstData?.dropOffZone === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "Drop off zone is required",
      }));
    }

    if (dispatchFirstData?.pickUpAddress !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        pickUpAddress: "",
      }));
    } else if (dispatchFirstData?.pickUpAddress === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        pickUpAddress: "Pick up address is required",
      }));
    }

    if (dispatchFirstData?.pickUpDate !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        pickUpDate: "",
      }));
    } else if (dispatchFirstData?.pickUpDate === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        pickUpDate: "Pick up date is required",
      }));
    }

    if (dispatchFirstData?.dropOffAddress !== "" && !useSmartLocker) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffAddress: "",
      }));
    } else if (dispatchFirstData?.dropOffAddress === "" && !useSmartLocker) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffAddress: "Drop off address is required",
      }));
    }

    if (dispatchFirstData?.dropOffLocker !== "" && useSmartLocker) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffLocker: "",
      }));
    } else if (dispatchFirstData?.dropOffLocker === "" && useSmartLocker) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffLocker: "Select a drop off address is required",
      }));
    }

    console.log("dispatchFirstData.weight", dispatchFirstData.weight)

    try {
      onGenerate();
      await checkValidate();
      if (
        infoErrorMessages.dropOffLocker === "" &&
        (((dispatchFirstData.dropOffAddress !== "" ||
          dispatchFirstData.dropOffLocker !== "") &&
          (!isLockerAvailabilityError || lockerAvailabilityData?.Available) &&
          infoErrorMessages.dropOffAddress === "" &&
          errorCost === "" &&
          useSmartLocker) ||
          !useSmartLocker) &&
        !isNaN(Number(dispatchFirstData.weight)) &&
        costError === "no" &&
        orderStatus !== false &&
        !isCostLoading &&
        !isLockerLoading &&
        errorCost === "" &&
        infoErrorMessages.weight === "" &&
        dispatchFirstData.pickUpDate !== "" &&
        dispatchFirstData.dropOffZone !== "" &&
        dispatchFirstData.deliveryType !== "" &&
        infoErrorMessages.pickUpDate === "" &&
        dispatchFirstData.weight !== "" &&
        // dispatchFirstData.weight &&
        infoErrorMessages.weight === "" &&
        infoErrorMessages.dropOffAddress === "" &&
        startDate !== null &&
        infoErrorMessages.pickUpZone === "" &&
        infoErrorMessages.dropOffZone === "" &&
        infoErrorMessages.pickUpAddress === "" &&
        dispatchFirstData.pickUpZone !== "" &&
        dispatchFirstData.pickUpDate !== "" &&
        dispatchFirstData.pickUpAddress !== ""
      ) {
        setStep(step + 1);
      } else {
        setStep(1);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (costData) {
      setCostDataResult(costData);
    }
    if (intlCostData) {
      setCostDataResult(intlCostData);
    }
  }, [costData, intlCostData]);

  useEffect(() => {
    if (
      costDataResult?.orderLocked === "YF" ||
      costDataResult?.orderLocked === "YS" ||
      costDataResult?.orderLocked === "Y" ||
      costData?.discountedRate === "0.00" ||
      intlCostData?.discountedRate === "0.00" ||
      Number(costData?.discountedRate) === 0 ||
      Number(intlCostData?.discountedRate) === 0 ||
      intlCostData?.status === "Error" ||
      (costDataResult?.status === "Error" &&
        costDataResult?.orderLocked === "Y") ||
      (intlCostData?.status === "Error" && intlCostData?.orderLocked === "Y")
    ) {
      setOrderStatus(false);
    }

    if (
      costData?.discountedRate === "0.00" ||
      Number(costData?.discountedRate) === 0 ||
      intlCostData?.discountedRate === "0.00" ||
      Number(intlCostData?.discountedRate) === 0
    ) {
      const timer = setTimeout(() => {
        setErrorCost("Network Error!");
      }, 3000);
      setOrderStatus(false);
      return () => clearTimeout(timer);
    }
  }, [costData, costDataResult, intlCostData]);

  useEffect(() => {
    if (orderStatus === false) {
      setCostError("Network Error! Please, try again!");
    }
  }, [orderStatus]);

  useEffect(() => {
    if (
      costData?.orderLocked === "YF" ||
      costData?.orderLocked === "YS" ||
      costData?.orderLocked === "Y" ||
      intlCostData?.orderLocked === "YF" ||
      intlCostData?.orderLocked === "YS" ||
      intlCostData?.orderLocked === "Y" ||
      (costData?.status === "Error" && costData?.orderLocked === "Y") ||
      (intlCostData?.status === "Error" && intlCostData?.orderLocked === "Y") ||
      costData?.status === "Error" ||
      costData?.orderLocked === "D" ||
      intlCostData?.status === "Error" ||
      intlCostData?.orderLocked === "D"
    ) {
      setShowOrderError(true);
    }
  }, [costData, intlCostData]);

  const getIntlCostData:any = async () => {
    setErrorCost("");
    setCostError("no");
    setOrderStatus(true);
    try {
      const requestPayload = {
        zone: dispatchFirstData.dropOffLocationId,
        weight: Number(weight.id),
      };

      await getIntlCost(requestPayload).unwrap();
    } catch (error) {
      setCostError("Network Error! Please, try again!");
    }
  };

  const deleteDataOrder = async () => {
    try {
      const requestPayload = {
        orderNo: localStorage.getItem("orderNo"),
      };
      await deleteOrder(requestPayload).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (deleteData || deleteDataError) {
      getMultipleData();
    }
  }, [deleteData, deleteDataError]);

  const getIntlReferralCostData = async () => {
    setErrorCost("");
    setCostError("no");
    setOrderStatus(true);
    try {
      const requestPayload = {
        zone: dispatchFirstData.dropOffLocationId,
        weight:Number(dispatchFirstData.weight),
        referralCode: dispatchFirstData.referralCode,
      };
      await getIntlReferralCost(requestPayload).unwrap();
    } catch (error) {
      setCostError("Network Error! Please, try again!");
    }
  };

  useEffect(() => {
    if (applyReferralTwo) {
      getIntlReferralCostData();
    }
  }, [
    dispatchFirstData.dropOffLocationId,
    dispatchFirstData.weight,
    applyReferralTwo,
  ]);

  useEffect(() => {
    if (
      dispatchFirstData.dropOffZone !== "" &&
      dispatchFirstData.weight !== "" &&
      queryDeliverytype.toLowerCase() !== "local"
    ) {
      getIntlCostData();
    }
  }, [
    dispatchFirstData.pickUpZone,
    dispatchFirstData.dropOffZone,
    dispatchFirstData.weight,
    queryDeliverytype,
  ]);

  useEffect(() => {
    if (
      (costDataResult?.orderLocked !== "YF" ||
        costDataResult?.orderLocked !== "YS" ||
        costDataResult?.orderLocked !== "Y") &&
      costDataResult?.status === "Error" &&
      costDataResult?.orderLocked !== "D"
    ) {
      setShowOrderErrorThree(true);
    }
  }, [costData]);

  useEffect(() => {
    if (
      costData?.orderLocked === "YF" ||
      costData?.orderLocked === "YS" ||
      costData?.orderLocked === "Y" ||
      intlCostData?.orderLocked === "YF" ||
      intlCostData?.orderLocked === "YS" ||
      intlCostData?.orderLocked === "Y"
    ) {
      setShowOrderErrorOne(true);
    }
  }, [costData, intlCostData]);

  useEffect(() => {
    if (
      dispatchFirstData.pickUpZone !== "" &&
      dispatchFirstData.dropOffZone !== "" &&
      dispatchFirstData.weight !== "" &&
      queryDeliverytype.toLowerCase() === "local"
    ) {
      getCostPriceData();
    }
  }, [
    dispatchFirstData.pickUpZone,
    dispatchFirstData.dropOffZone,
    queryDeliverytype,
    dispatchFirstData.weight,
    lockerAvailabilityData?.Available,
    useSmartLocker,
  ]);

  const getReferralData = async () => {
    setErrorCost("");
    try {
      const requestPayload = {
        ori_zone: dispatchFirstData.pickUpZone,
        des_zone: dispatchFirstData.dropOffZone,
        deliveryType: dispatchFirstData.deliveryType,
        referralCode: dispatchFirstData.referralCode,
        weight: Number(dispatchFirstData.weight),
      };
      await getReferralCode(requestPayload).unwrap();
    } catch (error) {
      setErrorCost("Network Error! Please, try again!");
    }
  };

  useEffect(() => {
    if (applyReferral) {
      onApplyReferral();
    }
  }, [
    dispatchFirstData.pickUpZone,
    dispatchFirstData.dropOffZone,
    dispatchFirstData.deliveryType,
    dispatchFirstData.weight,
    applyReferral,
  ]);

  const onApplyReferral = () => {
    if (
      dispatchFirstData.pickUpZone !== "" &&
      dispatchFirstData.dropOffZone !== "" &&
      dispatchFirstData.referralCode !== "" &&
      dispatchFirstData.deliveryType !== ""
    ) {
      getReferralData();
    }
  };

  const onFinishStepTwo = async () => {
    try {
      await checkValidateTwo();
      if (
        dispatchFirstData.senderName !== "" &&
        dispatchFirstData.senderPhone !== "" &&
        !isNaN(Number(dispatchFirstData.senderPhone)) &&
        dispatchFirstData.senderEmail !== "" &&
        dispatchFirstData.recipientName !== "" &&
        dispatchFirstData.recipientPhone !== "" &&
        !isNaN(Number(dispatchFirstData.recipientPhone)) &&
        dispatchFirstData.quantity !== "" &&
        !isNaN(Number(dispatchFirstData.quantity)) &&
        dispatchFirstData.itemDescription !== "" &&
        infoErrorMessages.cost === "" &&
        dispatchFirstData.cost !== "" &&
        !isNaN(Number(dispatchFirstData.cost))
      ) {
        setStep(step + 1);
      } else {
        console.log("err");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const toggleDropdown = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (isOpen) {
      setIsOpenDeliveryType(false);
      setIsOpenTwo(false);
    }
  }, [isOpen]);
  useEffect(() => {
    if (isOpenDeliveryType) {
      setIsOpen(false);
      setIsOpenTwo(false);
    }
  }, [isOpenDeliveryType]);

  useEffect(() => {
    if (isOpenTwo) {
      setIsOpen(false);
      setIsOpenDeliveryType(false);
    }
  }, [isOpenTwo]);

  const updateReferalCode = async () => {
    const orderNos = localStorage.getItem("orderNo");
    try {
      const body: RefProps = {
        orderNo: orderNos ? orderNos : "",
      };
      if (
        referralData?.status === "00" &&
        referralData?.discountedRate !== ""
      ) {
        body.referralCode = dispatchFirstData.referralCode;
        await updateReference(body).unwrap();
      }
      if (
        intlReferralData?.status === "00" &&
        intlReferralData?.discountedRate !== ""
      ) {
        body.referralCode = dispatchFirstData.referralCode;
        await updateReference(body).unwrap();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSuccess = () => {
    updateReferalCode();
    setShowPaymentSuccessModal(true);
    setDispatchFirstData({
      pickUpAddress: "",
      pickUpZone: "",
      pickUpLocationId: "",
      pickUpLocationName: "",
      dropOffAddress: "",
      dropOffZone: "",
      dropOffLocationId: "",
      dropOffLocationName: "",
      deliveryType: "",
      pickUpDate: "",
      senderName: "",
      senderPhone: "",
      senderAltPhone: "",
      senderEmail: "",
      recipientEmail: "",
      recipientName: "",
      recipientPhone: "",
      itemDescription: "",
      quantity: "",
      referenceId: "",
      deliveryMode: "",
      weight: "",
      cost: "",
      additionalNote: "",
      referralCode: "",
      dropOffLocker: "",
      dropOffLockerAddress: "",
    });
    setQuery("");
    setQueryTwo("");
    setStartDate(null);
  };
  const onClose = () => {
    onGenerate();
    setRegenerate(true);
    console.log("closed");
  };
  const handleReload = () => {
    window.location.reload();
  };

  const list = [
    {
      id: 1,
      name: "Local",
    },
    {
      id: 2,
      name: "International",
    },
  ];

  useEffect(() => {
    if (deliveryType?.toLowerCase() === "local") {
      setQueryDeliveryType("Local");
    } else {
      setQueryDeliveryType("International");
    }
  }, [deliveryType]);

  const formattedTextElement = document.getElementById("formattedText");
  if (formattedTextElement) {
    if (termsAndCondition && termsAndCondition?.terms) {
      formattedTextElement.textContent = termsAndCondition?.terms;
    } else {
      formattedTextElement.textContent = "Terms and conditions not available.";
    }
  } else {
    {
    }
  }

  const handleSelectLocker = (value: any, label: any) => {
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffLocker: value,
    }));
    setInfoErrorMessages((prev) => ({
      ...prev,
      dropOffLocker: "",
      dropOffAddress:"",
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffLockerAddress: label,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffAddress: "",
    }));
  };

  useEffect(() => {
    if (
      lockerData &&
      dispatchFirstData?.dropOffLocker !== "" &&
      dispatchFirstData?.weight !== ""
    ) {
      if (lockerData?.maxWeight < Number(dispatchFirstData?.weight)) {
        setInfoErrorMessages((prev) => ({
          ...prev,
          weight: "Weight is more than locker limit",
        }));
      }
    }
  }, [dispatchFirstData?.dropOffLocker, dispatchFirstData?.weight, lockerData]);

  useEffect(() => {
    if (
      lockerData &&
      dispatchFirstData?.dropOffLocker !== "" &&
      dispatchFirstData?.cost !== ""
    ) {
      if (lockerData?.maxValueOfItem < Number(dispatchFirstData?.cost)) {
        setInfoErrorMessages((prev) => ({
          ...prev,
          cost: "Value of item is more than locker value of item",
        }));
      }
    }
  }, [dispatchFirstData?.dropOffLocker, dispatchFirstData?.cost, lockerData]);

  useEffect(() => {
    if (dispatchFirstData?.dropOffLocker !== "" && useSmartLocker) {
      const getLockerAvailability = async () => {
        try {
          const body = {
            lockerID: dispatchFirstData?.dropOffLocker,
          };
          await getLockersAvailability(body).unwrap();
        } catch (error) {
          console.log(error);
        }
      };
      getLockerAvailability();
    }
  }, [dispatchFirstData?.dropOffLocker, useSmartLocker]);

  return (
    <PageLayout>
      {step === 1 && (
        <div className=" mb-32 ">
          <div className="flex md:flex-row flex-col md:space-y-0 space-y-20 bg-[#EFF6EE] px-5 min-h-[700px] pt-24 sm:px-10 xl:px-20 md:space-x-5  md:items-center md:justify-between  ">
            <div className="animate__animated animate__fadeInTopLeft">
              <h2 className="xl:text-[69px] font-ppmigra lg:text-[60px] text-[40px] md:text-[50px] whitespace-nowrap text-textColor font-bold">
                Dispatch Now
              </h2>
              <p className="text-[18px] xl:block hidden font-[500]">
                Our smart solution can help meet your delivery <br /> needs in a
                quick and convenient manner.
              </p>
              <p className="text-[18px] font-onestMedium xl:hidden block ">
                Our smart solution can help meet your delivery needs in a quick
                and convenient manner.
              </p>
            </div>
            <div className="animate__animated animate__fadeInTopRight">
              <img src="/images/all-car.svg" alt="all-car" />
            </div>
          </div>
          <div className="mt-5  w-full px-5 sm:px-10 xl:px-20 md:flex-row flex-col md:space-y-0 space-y-5   flex md:justify-between ">
            <div className=" w-full ">
              <h3 className="md:text-[32px] text-[24px] font-ppmigra text-textColor">
                Smart delivery solution
              </h3>
              <InformationLayout onClick={() => setShowTerms(true)} />
            </div>

            <div className="w-full ">
              <h4 className="text-textColor font-onestBold text-[20px]">
                Delivery details
              </h4>
              {errorCost !== "" && (
                <div className="bg-[#f19797] border-[red]  border max-w-[500px] px-5 py-2 rounded-md">
                  <p className="text-white items-center flex text-[14px]  font-onestMedium">
                    {errorCost}
                  </p>
                </div>
              )}
              <div className="mt-5 space-y-3 max-w-[500px]">
                <DropDownSelect
                  placeholder="Select delivery type"
                  lists={list}
                  // toggleDropdown={() =>
                  //   setIsOpenDeliveryType(!isOpenDeliveryType)
                  // }
                  isRequired={true}
                  handleOptionClick={() => {}}
                  // isOpen={isOpenDeliveryType}
                  // setIsOpen={setIsOpenDeliveryType}
                  setQuery={setQueryDeliveryType}
                  query={queryDeliverytype}
                  name="deliveryType"
                  title="Select Delivery Type"
                  label={"Delivery type"}
                  disabled={true}
                />
                {queryDeliverytype.toLowerCase() === "local" && (
                  <DropDownSelect
                    placeholder="Select delivery mode"
                    // lists={list}
                    // toggleDropdown={() =>
                    //   setIsOpenDeliveryType(!isOpenDeliveryType)
                    // }
                    disabled={true}
                    // handleOptionClick={handleOptionClickThree}
                    isOpen={isOpenDeliveryType}
                    // setIsOpen={setIsOpenDeliveryType}
                    // setQuery={setQueryDeliveryType}
                    query={"Bike"}
                    name="deliveryMode"
                    title="Select Delivery Mode"
                    className="mb-10"
                    label={"Delivery type"}
                  />
                )}
                <div>
                  <p className="text-[#000000] items-center flex mt-5 text-[14px]  font-onestMedium">
                    Pick up Date{" "}
                    <span className="text-red-500 text-[20px]">*</span>
                  </p>
                  <DatePicker
                    name="pickUpDate"
                    selected={startDate}
                    onFocus={onFocusDate}
                    minDate={moment().toDate()}
                    onChange={onChangeDate}
                    className={`text-grey p-2  text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border-2  border-inputBorderGreen  focus:border-2  focus:border-border-2 focus:border-inputBorderGreen rounded-[8px] bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full`}
                  />
                  {infoErrorMessages.pickUpDate && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.pickUpDate}
                    </p>
                  )}
                </div>
                {queryDeliverytype.toLowerCase() !== "local" && (
                  <div>
                    <DropDownSelect
                      placeholder="Select Weight"
                      lists={intlWeightData?.weight}
                      toggleDropdown={() => setIsOpenWeight(!isOpenWeight)}
                      isRequired
                      handleOptionClick={handleWeightType}
                      isOpen={isOpenWeight}
                      setIsOpen={setIsOpenDeliveryType}
                      setQuery={setQueryDeliveryType}
                      query={weight.name}
                      name="weight"
                      title="Select weight"
                      label={"weight type"}
                    />
                    {infoErrorMessages.weight && (
                      <p className="text-red-500 text-[12px]">
                        {infoErrorMessages.weight}
                      </p>
                    )}
                  </div>
                )}

                <div>
                  <SearchableDropdown
                    name="pickUpZone"
                    lists={filteredOptions}
                    toggleDropdown={toggleDropdown}
                    handleOptionClick={handleOptionClick}
                    title="Select Pick-up Zone"
                    label={"Pickup zone"}
                    isOpen={isOpen}
                    onFocus={focusHandlePickUp}
                    setIsOpen={setIsOpen}
                    setQuery={setQuery}
                    query={query}
                    disabled={queryDeliverytype.toLowerCase() !== "local"}
                    isRequired
                    setZoneOption={setZoneOption}
                  />

                  {infoErrorMessages.pickUpZone && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.pickUpZone}
                    </p>
                  )}
                </div>

                <div>
                  <Input
                    type="text"
                    name="pickUpAddress"
                    value={dispatchFirstData.pickUpAddress}
                    onChange={handleChange}
                    label="Pick-up Address"
                    isRequired
                    placeholder="Enter address"
                    labelFor="dropoffAddress"
                  />
                  {infoErrorMessages?.pickUpAddress && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.pickUpAddress}
                    </p>
                  )}
                </div>
                <div>
                  {queryDeliverytype.toLowerCase() === "local" ? (
                    <SearchableDropdown
                      lists={filteredDropOffOptions}
                      handleOptionClick={handleOptionClickTwo}
                      onFocus={focusHandleDropOff}
                      title="Select Drop-off Zone"
                      label={"Drop Zone"}
                      isOpen={isOpenTwo}
                      name="dropOffZone"
                      setIsOpen={setIsOpenTwo}
                      setQuery={setQueryTwo}
                      query={queryTwo}
                      isRequired
                      setZoneOption={setZoneOptionTwo}
                      toggleDropdown={() => setIsOpenTwo(!isOpenTwo)}
                    />
                  ) : (
                    <SearchableDropdown
                      intlLists={filteredIntLocations}
                      handleOptionClick={handleIntlOption}
                      onFocus={focusHandleDropOff}
                      title="Select Drop-off Zone"
                      label={"Drop-off Zone"}
                      isOpen={isOpenTwo}
                      name="dropOffZone"
                      setIsOpen={setIsOpenTwo}
                      setQuery={setQueryTwo}
                      weight={dispatchFirstData.weight}
                      query={queryTwo}
                      isRequired
                      errorList={dispatchFirstData.dropOffZone}
                      setZoneOption={setZoneOptionTwo}
                      toggleDropdown={() => setIsOpenTwo(!isOpenTwo)}
                    />
                  )}
                  {infoErrorMessages.dropOffZone && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.dropOffZone}
                    </p>
                  )}
                </div>
                {dropOffState !== null &&
                  dropOffState !== undefined &&
                  pickUpState !== null &&
                  pickUpState !== undefined &&
                  dropOffState === pickUpState && (
                    <>
                      {isLockerLoading && (
                        <p>....searching for dropoff locker</p>
                      )}
                      {queryDeliverytype.toLowerCase() === "local" &&
                        lockerData?.Lockers?.length > 0 && (
                          <div className="flex items-center space-x-1">
                            <input
                              type="checkbox"
                              checked={useSmartLocker}
                              required={true}
                              className="cursor-pointer"
                              onChange={(e: any) => {
                                setUseSmartLocker(e.target.checked);
                                setDispatchFirstData((prev) => ({
                                  ...prev,
                                  dropOffAddress: "",
                                  dropOffLockerAddress: "",
                                }));
                              }}
                            />
                            <p className="text-sm font-onestMedium">
                              Send to our smart Locker (Enjoy up to 50%
                              discount)
                            </p>
                          </div>
                        )}
                    </>
                  )}
                {!useSmartLocker && (
                  <div>
                    <Input
                      type="text"
                      value={dispatchFirstData.dropOffAddress}
                      onChange={handleChange}
                      label="Drop-off Address"
                      name="dropOffAddress"
                      placeholder="Enter address"
                      labelFor="dropoffAddress"
                      isRequired
                    />
                    {infoErrorMessages.dropOffAddress && (
                      <p className="text-red-500 text-[12px]">
                        {infoErrorMessages.dropOffAddress}
                      </p>
                    )}
                  </div>
                )}

                {useSmartLocker && (
                  <div className="mt-3">
                    <SelectInput
                      searchLoading={isLockerLoading}
                      value={dispatchFirstData?.dropOffLockerAddress}
                      name="dropOffLocker"
                      placeholder={"Select Drop-off Address"}
                      handleChange={(name, value, label) =>
                        handleSelectLocker(value, label)
                      }
                      label={"Select Drop-off Address"}
                      dropdownOptions={lockerData?.Lockers.map((item: any) => ({
                        label: item?.lockerAddress,
                        value: item?.lockerID,
                      }))}
                    />
                    {infoErrorMessages.dropOffLocker && (
                      <p className="text-red-500 text-[12px]">
                        {infoErrorMessages.dropOffLocker}
                      </p>
                    )}
                  </div>
                )}

                {!lockerAvailabilityData?.Available &&
                  useSmartLocker &&
                  !isLockerAvailabilityLoading &&
                  dispatchFirstData?.dropOffLockerAddress && (
                    <div>
                      <p className="text-[red] text-sm">
                        {" "}
                        It appears the locker selected is at capacity, please
                        select another locker or{" "}
                        <span
                          className="underline cursor-pointer font-onestMedium"
                          onClick={(e: any) => {
                            setUseSmartLocker(false);
                            setDispatchFirstData((prev) => ({
                              ...prev,
                              dropOffAddress: "",
                              dropOffLockerAddress: "",
                            }));
                          }}
                        >
                          click here
                        </span>{" "}
                        to fill a drop off address instead
                      </p>
                    </div>
                  )}
                {isLockerAvailabilityLoading && (
                  <p>....searching for available locker</p>
                )}
                {queryDeliverytype.toLowerCase() === "local" && (
                  <div>
                    <Input
                      type="text"
                      label="Weight"
                      placeholder=""
                      className="w-full"
                      weightShow
                      name="weight"
                      isRequired
                      value={dispatchFirstData.weight}
                      onChange={handleChange}
                    />
                    {infoErrorMessages.weight && (
                      <p className="text-red-500 text-[12px]">
                        {infoErrorMessages.weight}
                      </p>
                    )}
                  </div>
                )}
              </div>

              <div className="mt-10 max-w-[500px] ">
                <Button
                  title="Next"
                  className="!py-3"
                  onClick={onFinishStepOne}
                  disabled={isCostLoading || isLoadingIntlCost}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className=" mb-32 ">
          <SectionBack
            imgStr="/images/all-car.svg"
            onClick={() => setStep(step - 1)}
            content=" Dispatch Now"
          />

          <div className="mt-10  w-full px-5 sm:px-10 xl:px-20 md:flex-row flex-col md:space-y-0 space-y-5   flex md:justify-between ">
            <div className=" w-full ">
              <h3 className="md:text-[32px] text-[24px] font-ppmigra text-textColor">
                Smart delivery solution
              </h3>
              <InformationLayout onClick={() => setShowTerms(true)} />
            </div>
            <div className="w-full ">
              {errorCost !== "" && (
                <div className="bg-[#f19797] border-[red]  border max-w-[500px] px-5 py-2 rounded-md">
                  <p className="text-white items-center flex text-[14px]  font-onestMedium">
                    {errorCost}
                  </p>
                </div>
              )}
              <div className="mt-2 space-y-3 max-w-[500px]">
                <p className="text-textGreen font-onestBold text-[16px] mt-3">
                  SENDERS INFORMATION
                </p>
                <div>
                  <Input
                    type="text"
                    name="senderName"
                    label="Sender's Name"
                    placeholder="Enter name"
                    labelFor="sendersName"
                    value={dispatchFirstData.senderName}
                    isRequired
                    onChange={handleChangeTwo}
                  />
                  {infoErrorMessages.senderName && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.senderName}
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    type="text"
                    value={dispatchFirstData.senderPhone}
                    name="senderPhone"
                    label="Sender’s Phone-number"
                    placeholder="Enter phone number"
                    labelFor="phoneNumber"
                    onChange={handleChangeTwo}
                    isRequired
                  />

                  {infoErrorMessages.senderPhone && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.senderPhone}
                    </p>
                  )}
                </div>
                <div>
                  <p className="text-sm mt-4 text-[grey] font-onestMedium mb-2">
                    If you are sending this order on behalf of someone, please
                    enter your phone number
                  </p>
                  <Input
                    type="text"
                    value={dispatchFirstData.senderAltPhone}
                    name="senderAltPhone"
                    label="Sender’s Alternative Phone number"
                    placeholder="Enter phone number"
                    labelFor="phoneNumber"
                    onChange={handleChangeTwo}
                  />

                  {infoErrorMessages.senderAltPhone && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.senderAltPhone}
                    </p>
                  )}
                </div>

                <div>
                  <Input
                    type="email"
                    name="senderEmail"
                    value={dispatchFirstData.senderEmail}
                    label="Sender’s Email"
                    placeholder="Enter email address"
                    labelFor="senderEmail"
                    onChange={handleChangeTwo}
                    isRequired
                  />

                  {infoErrorMessages.senderEmail && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.senderEmail}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-8 space-y-3 max-w-[500px]">
                <p className="text-textGreen font-onestBold text-[16px] ">
                  RECIPIENT INFORMATION
                </p>

                <div>
                  <Input
                    name="recipientName"
                    type="text"
                    label="Recipient's Name"
                    value={dispatchFirstData.recipientName}
                    placeholder="Enter name"
                    labelFor="recipientName"
                    onChange={handleChangeTwo}
                    isRequired
                  />

                  {infoErrorMessages.recipientName && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.recipientName}
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    name="recipientEmail"
                    type="email"
                    label="Recipient's Email"
                    value={dispatchFirstData.recipientEmail}
                    placeholder="Enter email"
                    labelFor="recipientEmail"
                    onChange={handleChangeTwo}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name="recipientPhone"
                    value={dispatchFirstData.recipientPhone}
                    label="Recipient’s Phone number"
                    placeholder="Enter phone number"
                    labelFor="recipientPhoneNumber"
                    isRequired
                    onChange={handleChangeTwo}
                  />

                  {infoErrorMessages.recipientPhone && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.recipientPhone}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-8 space-y-3 max-w-[500px]">
                <p className="text-textGreen font-onestBold text-[16px] ">
                  PACKAGE DETAILS
                </p>

                <div>
                  <TextArea
                    label="Item Description"
                    placeholder="Enter description"
                    labelFor="description"
                    name="itemDescription"
                    value={dispatchFirstData.itemDescription}
                    onChange={handleChangeTwo}
                    isRequired
                  />

                  {infoErrorMessages.itemDescription && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.itemDescription}
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    name="quantity"
                    type="text"
                    label="Quantity"
                    value={dispatchFirstData.quantity}
                    placeholder="Enter quantity"
                    labelFor="quantity"
                    isRequired
                    onChange={handleChangeTwo}
                  />

                  {infoErrorMessages.quantity && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.quantity}
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    type="text"
                    name="cost"
                    label="Value of Item in Nigerian Naira"
                    placeholder="Enter value"
                    labelFor="cost"
                    isRequired
                    value={dispatchFirstData.cost}
                    onChange={handleChangeTwo}
                  />

                  {infoErrorMessages.cost && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.cost}
                    </p>
                  )}
                </div>
                <TextArea
                  name="additionalNote"
                  label="Additional Note"
                  placeholder="Enter note"
                  labelFor="note"
                  value={dispatchFirstData.additionalNote}
                  onChange={handleChangeTwo}
                />
              </div>
              <div className="mt-10 space-y-2 max-w-[500px] ">
                <Button
                  title="Next"
                  className="!py-3"
                  onClick={onFinishStepTwo}
                  loading={isMultipleLoading}
                />
                <Button
                  title="Back"
                  className="!py-3 !bg-transparent"
                  onClick={() => setStep(step - 1)}
                  disabled={isMultipleLoading}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {step === 3 && (
        <div className=" mb-32 ">
          <SectionBack
            imgStr="/images/all-car.svg"
            onClick={() => setStep(step - 1)}
            content=" Dispatch Now"
          />
          <div className="mt-10  w-full px-5 sm:px-10 xl:px-20 md:flex-row flex-col md:space-y-0 space-y-5   flex md:justify-between ">
            <div className=" w-full ">
              <h3 className="md:text-[32px] text-[24px] font-ppmigra text-textColor">
                Smart delivery solution
              </h3>
              <InformationLayout onClick={() => setShowTerms(true)} />
            </div>
            <div className="w-full ">
              <div className="mt-3 space-y-3 max-w-[500px]">
                <p className="text-textGreen font-onestBold text-[16px] ">
                  TOTAL COST
                </p>
                <p className="font-onestRegular text-[14px] md:text-[15px]">
                  The total estimated cost for your delivery is
                </p>
                <div className="border rounded-[8px] px-3 py-3 border-[#C2C5C2] shadow  drop-shadow-2xl  shadow-shadow">
                  {queryDeliverytype.toLowerCase() === "local" ? (
                    <>
                      {referralData?.status === "00" &&
                      referralData?.discountedRate !== "" ? (
                        <div className="flex space-x-3">
                          <h2
                            className={`line-through text-red-500 font-onestBold text-[28px] md:text-[34px]`}
                          >
                            {` ₦ ${new Intl.NumberFormat().format(
                              Number(costData?.discountedRate)
                            )}`}
                          </h2>
                          <h2 className="font-onestBold text-textGreen text-[28px] md:text-[34px]">
                            {` ₦ ${new Intl.NumberFormat().format(
                              Number(referralData?.discountedRate)
                            )}`}
                          </h2>
                        </div>
                      ) : (
                        <>
                          {costData?.rate !== costData?.discountedRate! ? (
                            <div className="flex space-x-3">
                              <h2
                                className={`line-through text-red-500 font-onestBold text-[28px] md:text-[34px]`}
                              >
                                {` ₦ ${new Intl.NumberFormat().format(
                                  Number(costData?.rate)
                                )}`}
                              </h2>
                              <h2 className="font-onestBold text-textGreen text-[28px] md:text-[34px]">
                                {` ₦ ${new Intl.NumberFormat().format(
                                  Number(costData?.discountedRate)
                                )}`}
                              </h2>
                            </div>
                          ) : (
                            <div className="flex space-x-3">
                              <h2 className="font-onestBold text-[28px] md:text-[34px]">
                                ₦{" "}
                                {new Intl.NumberFormat().format(
                                  Number(costData?.discountedRate)
                                )}
                              </h2>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {intlReferralData?.status === "00" &&
                      intlReferralData?.discountedRate !== "" ? (
                        <div className="flex space-x-3">
                          <h2
                            className={`line-through text-red-500 font-onestBold text-[28px] md:text-[34px]`}
                          >
                            {` ₦ ${new Intl.NumberFormat().format(
                              Number(intlCostData?.discountedRate)
                            )}`}
                          </h2>
                          <h2 className="font-onestBold text-textGreen text-[28px] md:text-[34px]">
                            {` ₦ ${new Intl.NumberFormat().format(
                              Number(intlReferralData?.discountedRate)
                            )}`}
                          </h2>
                        </div>
                      ) : (
                        <div className="flex space-x-3">
                          <h2
                            className={` text-textGreen font-onestBold text-[28px] md:text-[34px]`}
                          >
                            {` ₦ ${new Intl.NumberFormat().format(
                              Number(intlCostData?.discountedRate)
                            )}`}
                          </h2>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              {queryDeliverytype?.toLowerCase() === "local" && (
                <div className="max-w-[500px] mt-5">
                  <p className="mb-3">Do you have Referral Code?</p>
                  <div className="flex items-center space-x-2">
                    <Input
                      type="text"
                      value={dispatchFirstData.referralCode}
                      onChange={handleChange}
                      label="Referral Code"
                      name="referralCode"
                      placeholder="Enter referral code"
                      labelFor="referralCode"
                    />
                    <Button
                      title="Apply"
                      loading={isReferralLoading}
                      className="mt-5 border border-btnColor !bg-[#E8FFE5]"
                      onClick={() => {
                        setApplyReferral(true);
                        onApplyReferral();
                      }}
                    />
                  </div>
                  {referralData?.status === "00" && (
                    <p className="text-green-600 text-[12px]">
                      Congratulations! Your referral code is valid.
                    </p>
                  )}
                  {referralData?.status === "01" && (
                    <p className="text-red-500 text-[12px]">
                      An error occurred while processing your referral code.
                    </p>
                  )}
                  {referralData?.status === "02" && (
                    <p className="text-red-500 text-[12px]">
                      The referral code you used has expired. Please try a
                      different one.
                    </p>
                  )}
                  {referralData?.status === "03" && (
                    <p className="text-red-500 text-[12px]">
                      Oops! The referral code you entered is not valid
                    </p>
                  )}
                  {referralData?.status === "04" && (
                    <p className="text-red-500 text-[12px]">
                      The order limit for the referral code has been reached.
                    </p>
                  )}
                  {referralData?.status === "05" && (
                    <p className="text-red-500 text-[12px]">
                      Oops! This referral code is not currently active.
                    </p>
                  )}
                </div>
              )}
              {queryDeliverytype?.toLowerCase() !== "local" && (
                <div className="max-w-[500px] mt-5">
                  <p className="mb-3">Do you have Referral Code?</p>
                  <div className="flex items-center space-x-2">
                    <Input
                      type="text"
                      value={dispatchFirstData.referralCode}
                      onChange={handleChange}
                      label="Referral Code"
                      name="referralCode"
                      placeholder="Enter referral code"
                      labelFor="referralCode"
                    />
                    <Button
                      title="Apply"
                      loading={isLoadingReferralCost}
                      className="mt-5 border border-btnColor !bg-[#E8FFE5]"
                      onClick={() => {
                        setApplyReferralTwo(true);
                        getIntlReferralCostData();
                      }}
                    />
                  </div>
                  {intlReferralData?.status === "00" && (
                    <p className="text-green-600 text-[12px]">
                      Congratulations! Your referral code is valid.
                    </p>
                  )}
                  {intlReferralData?.status === "01" && (
                    <p className="text-red-500 text-[12px]">
                      An error occurred while processing your referral code.
                    </p>
                  )}
                  {intlReferralData?.status === "02" && (
                    <p className="text-red-500 text-[12px]">
                      The referral code you used has expired. Please try a
                      different one.
                    </p>
                  )}
                  {intlReferralData?.status === "03" && (
                    <p className="text-red-500 text-[12px]">
                      Oops! The referral code you entered is not valid.
                    </p>
                  )}

                  {intlReferralData?.status === "04" && (
                    <p className="text-red-500 text-[12px]">
                      The order limit for the referral code has been reached.
                    </p>
                  )}
                  {intlReferralData?.status === "05" && (
                    <p className="text-red-500 text-[12px]">
                      Oops! This referral code is not currently active.
                    </p>
                  )}
                </div>
              )}
              <div className="mt-10 space-y-2 max-w-[500px] ">
                <Button
                  title="Pay"
                  loading={isMultipleLoading || deleteLoading}
                  className="!py-3"
                  onClick={() => {
                    if (regenerate) {
                      deleteDataOrder();
                    } else {
                      getMultipleData();
                    }
                  }}
                />
                <Button
                  title="Back"
                  className="!py-3 !bg-transparent"
                  onClick={() => setStep(step - 1)}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showPaymentSuccessModal && (
        <DashboardModalAction
          contentTitle={"Track Order Number"}
          content={`This is your track order number, please copy it `}
          btnContent="Close"
          trackNo={
            multipleData?.orderNos[0]
              ? multipleData?.orderNos[0]
              : localStorage.getItem("orderNo")
          }
          onClick={() => {
            setShowPaymentSuccessModal(false);
            handleReload();
            localStorage.removeItem("orderNo");
          }}
        />
      )}

      {showOrderError && (
        <ShowOrderLockYF onClick={() => setShowOrderError(false)} />
      )}

      {showOrderErrorOne && (
        <ShowOrderErrorandD onClick={() => setShowOrderErrorOne(false)} />
      )}

      {showOrderErrorThree && (
        <ShowErrorandYS onClick={() => setShowOrderErrorThree(false)} />
      )}
      {showTerms && (
        <ShowTermsModal
          onClick={() => setShowTerms(false)}
          terms={termsAndCondition?.terms}
          id="formattedText"
        />
      )}
      {showPriceModal && (
        <ExportInfoModal onClick={() => setShowPriceModal(false)} />
      )}
    </PageLayout>
  );
};

export default DispatchNow;
