/* eslint-disable */
import { useEffect, useState } from "react";
import { SearchableDropdown } from "../components/form/Dropdown";
import { Input } from "../components/form/Input";
import { Button } from "../components/Button";
import { TextArea } from "../components/form/textArea";
import { SectionBack } from "../components/SectionBack";
import { PageLayout } from "../components/Layout";
import { useGetDropOffZonesQuery } from "../services/slices/getZonesSlice";
import { usePaystackPayment } from "react-paystack";

import {
  useOrderMultipleMutation,
  useRemoveOrderMutation,
} from "../services/slices/multiple";
import {
  generateRandomDataWithDelay,
  scrollToTop,
  validateEmail,
} from "../helper";
import {
  DashboardModalAction,
  LasrraInfoModal,
  ShowErrorandYS,
  ShowOrderErrorandD,
  ShowOrderLockYF,
  ShowTermsModal,
} from "../components/Modal";
import { InformationLayout } from "../components/InfoLayout";
import { object, string, number } from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { useGetTermsQuery } from "../services/slices/terms";
import {
  useGetLockerAvailabilityMutation,
  useGetLockersMutation,
} from "../services/slices/lockers";
import SelectInput from "../components/form/SelectInput";

const LassraOrder = () => {
  const [step, setStep] = useState(1);
  const [dropOffZoneData, setDropOffZoneData] = useState([]);
  const [zoneOptionTwo, setZoneOptionTwo] = useState<any>(null);
  const [clientUserId, setClientUserId] = useState("");
  const [clientUserPhone, setClientUserPhone] = useState("");
  const [queryTwo, setQueryTwo] = useState("");
  const [costError, setCostError] = useState("no");
  const [referenceId, setReferenceId] = useState("");
  const [referenceIdTwo, setReferenceIdTwo] = useState("");
  const [isOpenTwo, setIsOpenTwo] = useState(false);
  const [showOrderError, setShowOrderError] = useState(false);
  const [showOrderErrorOne, setShowOrderErrorOne] = useState(false);
  const [regenerate, setRegenerate] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showOrderErrorThree, setShowOrderErrorThree] = useState(false);
  const [dropOffState, setDropOffState] = useState("");
const [lasrraModal, setLasrraModal] = useState(true)
  const [infoErrorMessages, setInfoErrorMessages] = useState({
    dropOffAddress: "",
    dropOffZone: "",
    dropOffLocationId: "",
    dropOffLocationName: "",
    pickUpDate: "",
    senderName: "",
    senderPhone: "",
    senderAltPhone: "",
    senderEmail: "",
    recipientName: "",
    recipientEmail: "",
    recipientPhone: "",
    itemDescription: "",
    quantity: "",
    referenceId: "",
    weight: "",
    cost: "",
    additionalNote: "",
    dropOffLocker: "",
    registrationNumber: "",
  });
  const [useSmartLocker, setUseSmartLocker] = useState(false);
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);
  const { data: dropOffData } = useGetDropOffZonesQuery();
  const { data: termsAndCondition } = useGetTermsQuery();

  const [
    getLockers,
    { data: lockerData, isLoading: isLockerLoading, isError: isLockerError },
  ] = useGetLockersMutation();
  const [
    getLockersAvailability,
    {
      data: lockerAvailabilityData,
      isLoading: isLockerAvailabilityLoading,
      isError: isLockerAvailabilityError,
    },
  ] = useGetLockerAvailabilityMutation();
  const [orderMultiple, { data: multipleData, isLoading: isMultipleLoading }] =
    useOrderMultipleMutation();

  const [
    deleteOrder,
    { data: deleteData, error: deleteDataError, isLoading: deleteLoading },
  ] = useRemoveOrderMutation();

  interface Props {
    requestType: string;
    clientAddress: string;
    recipientAddress: string;
    recipientName: string;
    recipientPhone: string;
    recipientEmail: string;
    quantity: number;
    weight: number;
    itemDescription: string;
    orderDate: any;
    cost: string;
    discount: string;
    discountedCost: string;
    paymentStatus: string;
    paymentReference: string;
    additionalNote: string;
    thirdparty: string;
    thirdparty_sendersName: string;
    thirdparty_sendersPhone: string;
    thirdparty_senders_Alt_Phone: string;
    clientEmail: string;
    zoneID: number;
    ZoneName: string;
    locationID: number;
    locationName: string;
    dropzoneID: number;
    dropZoneName: string;
    droplocationID: number;
    droplocationName: string;
    transportMode: string;
    deliveryType: string;
    valueOfItem: number;
    expanded: boolean;
    clientName: string;
    clientPhone: string;
    referralCode?: string;
    dropOffLocker?: string;
    lockerID?: string;
    source: string;
    dropOffLockerAddress?: string;
  }
  interface CostProps {
    ori_zone: string;
    des_zone: string;
    deliveryType: string;
    weight: number;
    locker?: boolean;
  }

  interface RefProps {
    orderNo: string;
    reference?: string;
    referralCode?: string;
  }

  const [dispatchFirstData, setDispatchFirstData] = useState({
    dropOffAddress: "",
    dropOffZone: "",
    dropOffLocationId: "",
    dropOffLocationName: "",
    deliveryType: "bike",
    deliveryMode: "bike",
    pickUpDate: "",
    recipientEmail: "",
    recipientName: "",
    recipientPhone: "",
    referenceId: "",
    additionalNote: "",
    dropOffLocker: "",
    dropOffLockerAddress: "",
    registrationNumber: "",
  });

  const userDetails: any = localStorage.getItem("fez-user");

  let userSchema = object({
    dropOffZone: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            dropOffZone: "Dropoff  zone  is required",
          }));
          throw new TypeError("Dropoff  zone  is required");
        }
        return value;
      })
      .required("Dropoff  zone  is required"),

    dropOffAddress: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            dropOffAddress: "Drop off address is required",
          }));
          throw new TypeError("Drop off address is required");
        }
        return value;
      })
      .required("drop off address is required"),

    registrationNumber: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            registrationNumber: "Registration No is required",
          }));
          throw new TypeError("Registration number  is required");
        }
        return value;
      })
      .required("registration number  is required"),
  });

  let userSchemaTwo = object({
    senderName: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderName: "Sender name is required",
          }));
          throw new TypeError("Sender name is required");
        }
        return value;
      })
      .required("Sender name is required"),
    recipientName: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            recipientName: "Recipient name is required",
          }));
          throw new TypeError("Recipient name is required");
        }
        return value;
      })
      .required("Recipient name is required"),
    registrationNumber: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            registrationNumber: "Registration No is required",
          }));
          throw new TypeError("Registration No  is required");
        }
        return value;
      })
      .required("Registration number  is required"),
    itemDescription: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            itemDescription: "Item description is required",
          }));
          throw new TypeError("Item description is required");
        }
        return value;
      })
      .required("Item description is required"),

    senderPhone: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderPhone: "phone number must be a valid number",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderPhone: "phone number is required",
          }));
          throw new TypeError("phone number must be a valid number");
        }
        return value;
      })
      .required("phone number must be a number")
      .positive()
      .integer(""),
    senderAltPhone: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderAltPhone: "phone number must be a valid number",
          }));
        }
        if (!isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderAltPhone: "",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderAltPhone: "",
          }));
        }
        return value;
      })
      .positive()
      .integer(""),
    recipientPhone: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            recipientPhone: "phone number must be a valid number",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            recipientPhone: "phone number is required",
          }));
          throw new TypeError("phone number must be a valid number");
        }
        return value;
      })
      .required("phone number must be a number")
      .positive()
      .integer(""),
    quantity: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            quantity: "quantity must be a valid number",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            quantity: "quantity is required",
          }));
          throw new TypeError("quantity must be a valid number");
        }
        return value;
      })
      .required("quantity must be a number")
      .positive()
      .integer(""),
    cost: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            cost: "cost must be a valid number",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            cost: "cost is required",
          }));
          throw new TypeError("cost must be a valid number");
        }
        return value;
      })
      .required("cost must be a number")
      .positive()
      .integer(""),
    senderEmail: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderEmail: "Email is required",
          }));
          throw new TypeError("Email is required");
        }
        return value;
      })
      .email("Invalid email format")
      .required("Email is required"),
  });

  const checkValidate = async () => {
    try {
      await userSchema.validate(dispatchFirstData);
    } catch (err: any) {
      const errorMessage = err?.errors?.join(", ");
    }
  };

  useEffect(() => {
    if (dispatchFirstData?.dropOffLockerAddress === "" && useSmartLocker) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffLocker: "Select a drop off locker",
      }));
    } else {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffLocker: "",
      }));
    }
  }, [useSmartLocker, dispatchFirstData?.dropOffLockerAddress]);
  const checkValidateTwo = async () => {
    try {
      await userSchemaTwo.validate(dispatchFirstData);
    } catch (err: any) {
      const errorMessage = err?.errors?.join(", ");
    }
  };

  useEffect(() => {
    if (
      dispatchFirstData?.recipientEmail !== "" &&
      validateEmail(dispatchFirstData?.recipientEmail) === null
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        recipientEmail: "Invalid email format",
      }));
    } else if (
      dispatchFirstData?.recipientEmail !== "" &&
      validateEmail(dispatchFirstData?.recipientEmail) !== null
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        recipientEmail: "",
      }));
    }
  }, [dispatchFirstData?.recipientEmail]);

  useEffect(() => {
    if (userDetails) {
      const { clientID, clientsPhone } = JSON.parse(userDetails);
      setClientUserId(clientID);
      setClientUserPhone(clientsPhone);
    }
  }, [userDetails]);

  const getMultipleData = async () => {
    try {
      const requestPayload: Props = {
        requestType: "local",
        clientAddress:
          "4 Registration Close, Off Lateef Jakande Road, Agidingbi, Ikeja",
        recipientAddress:
          dispatchFirstData.dropOffAddress !== ""
            ? dispatchFirstData.dropOffAddress
            : dispatchFirstData.dropOffLockerAddress,
        recipientEmail: dispatchFirstData.recipientEmail,
        recipientName: dispatchFirstData.recipientName,
        recipientPhone: dispatchFirstData.recipientPhone,
        quantity: 1,
        itemDescription: "Lasrra Cards",
        orderDate: new Date(),
        cost: "800",
        weight: 1,
        discount: "",
        discountedCost: "800",
        paymentStatus: "ppc",
        paymentReference: referenceId,
        additionalNote: `${dispatchFirstData.registrationNumber} - ${dispatchFirstData.additionalNote}`,
        thirdparty: "true",
        thirdparty_sendersName: "lasrra",
        thirdparty_sendersPhone: "",
        clientEmail: "Info@lagosresident.gov.ng",
        thirdparty_senders_Alt_Phone: "",
        zoneID: 79,
        ZoneName: "Ikeja",
        locationID: 407,
        locationName: "Lagos",
        dropzoneID: Number(dispatchFirstData.dropOffZone),
        dropZoneName: zoneOptionTwo,
        droplocationID: Number(dispatchFirstData.dropOffLocationId),
        droplocationName: dispatchFirstData.dropOffLocationName,
        transportMode: "Bike",
        deliveryType: "local",
        valueOfItem: 2000,
        expanded: false,
        clientName: clientUserId,
        clientPhone: clientUserPhone,
        source: "Fez Website",
      };

      if (
        lockerAvailabilityData?.Available &&
        dispatchFirstData?.dropOffLocker !== "" &&
        useSmartLocker
      ) {
        requestPayload.lockerID = dispatchFirstData?.dropOffLocker;
      }
      const response = await orderMultiple(requestPayload).unwrap();
      localStorage.setItem("orderNo", response?.orderNos[0]);
      if (response.status !== "Error") {
        initializePayment(onSuccess, onClose);
      }
    } catch (error) {
      setCostError("Network Error! Please, try again!");
    }
  };

  useEffect(() => {
    if (dropOffData) {
      setDropOffZoneData(dropOffData.zones);
    }
  }, [dropOffData]);

  const config = {
    reference: referenceIdTwo,
    email: "Info@lagosresident.gov.ng",
    amount: Number("800") * 100,
    publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
  };

  const initializePayment = usePaystackPayment(config);

  const filteredDropOffOptions = dropOffZoneData.reduce(
    (acc: any, zone: any) => {
      const filteredLocations =
        zone?.hub?.state_id === 2 &&
        zone.location.filter((location: any) =>
          location.location.toLowerCase().includes(queryTwo?.toLowerCase())
        ); // Filter by location name

      if (filteredLocations.length > 0) {
        acc.push({
          zone: zone?.hub?.state_id === 2 && zone.name,
          locations: filteredLocations,
          state: zone?.hub?.state_id === 2 && zone.hub?.state_id,
        });
      }
      return acc;
    },
    []
  );

  const handleOptionClickTwo = (option: any, state: any) => {
    if (option.location === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "Drop off zone is required",
      }));
    }
    if (option.location !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "",
      }));
    }

    setDropOffState(state);
    setQueryTwo(option.location);
    setIsOpenTwo(false);
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffLocationName: option.location,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffZone: option.zoneid,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffLocationId: option.id,
    }));
  };

  useEffect(() => {
    const getSmartLockerLocation = async () => {
      if (dispatchFirstData?.dropOffZone) {
        const body = {
          zone: dispatchFirstData?.dropOffZone,
        };
        await getLockers(body).unwrap();
      }
    };
    getSmartLockerLocation();
  }, [dispatchFirstData?.dropOffZone, useSmartLocker]);

  const focusHandleDropOff = () => {
    if (queryTwo === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "Drop off zone is required",
      }));
    }
    if (queryTwo !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "",
      }));
    }
  };

  const handleChange = async (evt: any) => {
    const { name, value } = evt.target;

    setDispatchFirstData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "dropOffAddress" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, dropOffAddress: "" }));
      setDispatchFirstData((prev) => ({
        ...prev,
        dropOffLocker: "",
      }));
    }

    try {
      await userSchema.validateAt(name, { [name]: value });
    } catch (error: any) {
      if (error) {
        // setInfoErrorMessage(error.message);
      }
    }
  };

  const handleChangeTwo = async (evt: any) => {
    const { name, value } = evt.target;

    setDispatchFirstData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "recipientName" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, recipientName: "" }));
    }
    if (name === "recipientPhone" && value !== "" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, recipientPhone: "" }));
    }
    if (name === "registrationNumber" && value !== "" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, registrationNumber: "" }));
    }

    try {
      await userSchemaTwo.validateAt(name, { [name]: value });
    } catch (error: any) {
      if (error) {
        // setInfoErrorMessage(error.message);
      }
    }
  };

  useEffect(() => {
    if (step === 2) {
      scrollToTop();
    }
  }, [step]);

  const onGenerate = () => {
    const response = generateRandomDataWithDelay();

    if (clientUserId) {
      setReferenceId(`c_${response}_${clientUserId}_OP`);
      setReferenceIdTwo(`${response}_${clientUserId}_OP`);
    }
  };

  const deleteDataOrder = async () => {
    try {
      const requestPayload = {
        orderNo: localStorage.getItem("orderNo"),
      };
      await deleteOrder(requestPayload).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (deleteData || deleteDataError) {
      getMultipleData();
    }
  }, [deleteData, deleteDataError]);

  const onFinishStepTwo = async () => {
    if (dispatchFirstData?.dropOffLockerAddress === "" && useSmartLocker) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffLocker: "Select a drop off address",
      }));
    } else if (
      dispatchFirstData?.dropOffLockerAddress !== "" &&
      useSmartLocker
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffLocker: "",
      }));
    }
    if (dispatchFirstData?.registrationNumber !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        registrationNumber: "",
      }));
    } else if (dispatchFirstData?.registrationNumber === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        registrationNumber: "Registration No is required",
      }));
    }

    if (dispatchFirstData?.dropOffZone !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "",
      }));
    } else if (dispatchFirstData?.dropOffZone === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "Drop off zone is required",
      }));
    }

    if (dispatchFirstData?.dropOffAddress !== "" && !useSmartLocker) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffAddress: "",
      }));
    } else if (dispatchFirstData?.dropOffAddress === "" && !useSmartLocker) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffAddress: "Drop off address is required",
      }));
    }

    if (dispatchFirstData?.dropOffLocker !== "" && useSmartLocker) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffLocker: "",
      }));
    } else if (dispatchFirstData?.dropOffAddress === "" && useSmartLocker) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffLocker: "Select a drop off address is required",
      }));
    }
    try {
      onGenerate();
      await checkValidate();
      await checkValidateTwo();
      if (
        (dispatchFirstData.recipientName !== "" &&
          dispatchFirstData.recipientPhone !== "" &&
          !isNaN(Number(dispatchFirstData.recipientPhone)) &&
          (dispatchFirstData.dropOffAddress !== "" ||
            dispatchFirstData.dropOffLocker !== "") &&
          (!isLockerAvailabilityError || lockerAvailabilityData?.Available) &&
          infoErrorMessages.dropOffAddress === "" &&
          !useSmartLocker) ||
        (infoErrorMessages.dropOffLocker === "" &&
          useSmartLocker &&
          costError === "no" &&
          !isLockerLoading &&
          dispatchFirstData.dropOffZone !== "" &&
          infoErrorMessages.dropOffZone === "")
      ) {
        setStep(step + 1);
      } else {
        console.log("err");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onSuccess = () => {
    setShowPaymentSuccessModal(true);
    setDispatchFirstData({
      dropOffAddress: "",
      dropOffZone: "",
      dropOffLocationId: "",
      dropOffLocationName: "",
      deliveryType: "",
      pickUpDate: "",
      recipientEmail: "",
      recipientName: "",
      recipientPhone: "",
      referenceId: "",
      deliveryMode: "",
      additionalNote: "",
      dropOffLocker: "",
      dropOffLockerAddress: "",
      registrationNumber: "",
    });
    setQueryTwo("");
  };
  const onClose = () => {
    onGenerate();
    setRegenerate(true);
    console.log("closed");
  };
  const handleReload = () => {
    window.location.reload();
  };

  const list = [
    {
      id: 1,
      name: "Local",
    },
    {
      id: 2,
      name: "International",
    },
  ];

  const formattedTextElement = document.getElementById("formattedText");
  if (formattedTextElement) {
    if (termsAndCondition && termsAndCondition?.terms) {
      formattedTextElement.textContent = termsAndCondition?.terms;
    } else {
      formattedTextElement.textContent = "Terms and conditions not available.";
    }
  } else {
    {
    }
  }

  const handleSelectLocker = (value: any, label: any) => {
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffLocker: value,
    }));
    setInfoErrorMessages((prev) => ({
      ...prev,
      dropOffLocker: "",
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffLockerAddress: label,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffAddress: "",
    }));
  };

  useEffect(() => {
    if (dispatchFirstData?.dropOffLocker !== "" && useSmartLocker) {
      const getLockerAvailability = async () => {
        try {
          const body = {
            lockerID: dispatchFirstData?.dropOffLocker,
          };
          await getLockersAvailability(body).unwrap();
        } catch (error) {
          console.log(error);
        }
      };
      getLockerAvailability();
    }
  }, [dispatchFirstData?.dropOffLocker, useSmartLocker]);

  return (
    <PageLayout>
      {step === 1 && (
        <div className=" mb-32 ">
          <div className="flex md:flex-row flex-col md:space-y-0 space-y-5 bg-[#EFF6EE] px-5 min-h-[500px] pt-24 sm:px-10 xl:px-20 md:space-x-5  md:items-center md:justify-between  ">
            <div className="animate__animated animate__fadeInTopLeft">
              <h2 className="xl:text-[49px] w-full md:w-[75%] lg:w-[60%] font-ppmigra lg:text-[40px] text-[20px] md:text-[40px]  text-textColor font-bold">
                Schedule Delivery of Your LASRRA Card
              </h2>
              <p className="text-[18px] xl:block hidden font-[500]">
                Our smart solution can help meet your delivery <br /> needs in a
                quick and convenient manner.
              </p>
              <p className="text-[18px] font-onestMedium xl:hidden block ">
                Our smart solution can help meet your delivery needs in a quick
                and convenient manner.
              </p>
            </div>
            <div className="animate__animated animate__fadeInTopRight">
              <img
                src="/images/all-car.svg"
                alt="all-car"
                className="h-[300px]"
              />
            </div>
          </div>
          <div className="mt-5  w-full px-5 sm:px-10 xl:px-20 md:flex-row flex-col md:space-y-0 space-y-5   flex md:justify-between ">
            <div className=" w-full ">
              <h3 className="md:text-[32px] text-[24px] font-ppmigra text-textColor">
                Smart delivery solution
              </h3>
              <InformationLayout onClick={() => setShowTerms(true)} lasrra />
            </div>

            <div className="w-full ">
              <h4 className="text-textColor font-onestBold text-[20px]">
                Delivery details
              </h4>

              <div className="mt-5 space-y-3 max-w-[500px]">
                <div>
                  <Input
                    name="registrationNumber"
                    type="text"
                    label="LASRRA Registration No"
                    value={dispatchFirstData.registrationNumber}
                    placeholder="Enter registration no"
                    labelFor="registrationNumber"
                    onChange={handleChangeTwo}
                    isRequired
                  />

                  {infoErrorMessages.registrationNumber && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.registrationNumber}
                    </p>
                  )}
                </div>

                <div>
                  <SearchableDropdown
                    lists={filteredDropOffOptions}
                    handleOptionClick={handleOptionClickTwo}
                    onFocus={focusHandleDropOff}
                    title="Select Drop-off Zone"
                    label={"Drop Zone"}
                    isOpen={isOpenTwo}
                    name="dropOffZone"
                    setIsOpen={setIsOpenTwo}
                    setQuery={setQueryTwo}
                    query={queryTwo}
                    isRequired
                    setZoneOption={setZoneOptionTwo}
                    toggleDropdown={() => setIsOpenTwo(!isOpenTwo)}
                  />

                  {infoErrorMessages.dropOffZone && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.dropOffZone}
                    </p>
                  )}
                </div>
                {dropOffState !== null && dropOffState !== undefined && (
                  <>
                    {isLockerLoading && <p>....searching for dropoff locker</p>}
                    {lockerData?.Lockers?.length > 0 && (
                      <div className="flex items-center space-x-1">
                        <input
                          type="checkbox"
                          checked={useSmartLocker}
                          required={true}
                          className="cursor-pointer"
                          onChange={(e: any) => {
                            setUseSmartLocker(e.target.checked);
                            setDispatchFirstData((prev) => ({
                              ...prev,
                              dropOffAddress: "",
                              dropOffLockerAddress: "",
                            }));
                          }}
                        />
                        <p className="text-sm font-onestMedium">
                          Send to our smart Locker (Enjoy up to 50% discount)
                        </p>
                      </div>
                    )}
                  </>
                )}
                {!useSmartLocker && (
                  <div>
                    <Input
                      type="text"
                      value={dispatchFirstData.dropOffAddress}
                      onChange={handleChange}
                      label="Drop-off Address"
                      name="dropOffAddress"
                      placeholder="Enter address"
                      labelFor="dropoffAddress"
                      isRequired
                    />
                    {infoErrorMessages.dropOffAddress && (
                      <p className="text-red-500 text-[12px]">
                        {infoErrorMessages.dropOffAddress}
                      </p>
                    )}
                  </div>
                )}

                {useSmartLocker && (
                  <div className="mt-3">
                    <SelectInput
                      searchLoading={isLockerLoading}
                      value={dispatchFirstData?.dropOffLockerAddress}
                      name="dropOffLocker"
                      placeholder={"Select Drop-off Address"}
                      handleChange={(name, value, label) =>
                        handleSelectLocker(value, label)
                      }
                      label={"Select Drop-off Address"}
                      dropdownOptions={lockerData?.Lockers.map((item: any) => ({
                        label: item?.lockerAddress,
                        value: item?.lockerID,
                      }))}
                    />
                    {infoErrorMessages.dropOffLocker && (
                      <p className="text-red-500 text-[12px]">
                        {infoErrorMessages.dropOffLocker}
                      </p>
                    )}
                  </div>
                )}

                {!lockerAvailabilityData?.Available &&
                  useSmartLocker &&
                  !isLockerAvailabilityLoading &&
                  dispatchFirstData?.dropOffLockerAddress && (
                    <div>
                      <p className="text-[red] text-sm">
                        {" "}
                        It appears the locker selected is at capacity, please
                        select another locker or{" "}
                        <span
                          className="underline cursor-pointer font-onestMedium"
                          onClick={(e: any) => {
                            setUseSmartLocker(false);
                            setDispatchFirstData((prev) => ({
                              ...prev,
                              dropOffAddress: "",
                              dropOffLockerAddress: "",
                            }));
                          }}
                        >
                          click here
                        </span>{" "}
                        to fill a drop off address instead
                      </p>
                    </div>
                  )}
                {isLockerAvailabilityLoading && (
                  <p>....searching for available locker</p>
                )}
              </div>
              <div className="mt-8 space-y-3 max-w-[500px]">
                <p className="text-textGreen font-onestBold text-[16px] ">
                  RECIPIENT INFORMATION
                </p>

                <div>
                  <Input
                    name="recipientName"
                    type="text"
                    label="Recipient's Name"
                    value={dispatchFirstData.recipientName}
                    placeholder="Enter name"
                    labelFor="recipientName"
                    onChange={handleChangeTwo}
                    isRequired
                  />

                  {infoErrorMessages.recipientName && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.recipientName}
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    name="recipientEmail"
                    type="email"
                    label="Recipient's Email"
                    value={dispatchFirstData.recipientEmail}
                    placeholder="Enter email"
                    labelFor="recipientEmail"
                    onChange={handleChangeTwo}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name="recipientPhone"
                    value={dispatchFirstData.recipientPhone}
                    label="Recipient’s Phone number"
                    placeholder="Enter phone number"
                    labelFor="recipientPhoneNumber"
                    isRequired
                    onChange={handleChangeTwo}
                  />

                  {infoErrorMessages.recipientPhone && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.recipientPhone}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-5 max-w-[500px]">
                <TextArea
                  name="additionalNote"
                  label="Additional Note"
                  placeholder="Enter note"
                  labelFor="note"
                  value={dispatchFirstData.additionalNote}
                  onChange={handleChangeTwo}
                />
              </div>
              <div className="mt-10 space-y-2 max-w-[500px] ">
                <Button
                  title="Next"
                  className="!py-3"
                  onClick={onFinishStepTwo}
                  loading={isMultipleLoading}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className=" mb-32 ">
          <SectionBack
            imgStr="/images/all-car.svg"
            onClick={() => setStep(step - 1)}
            content=" Dispatch Now"
          />
          <div className="mt-10  w-full px-5 sm:px-10 xl:px-20 md:flex-row flex-col md:space-y-0 space-y-5   flex md:justify-between ">
            <div className=" w-full ">
              <h3 className="md:text-[32px] text-[24px] font-ppmigra text-textColor">
                Smart delivery solution
              </h3>
              <InformationLayout onClick={() => setShowTerms(true)} lasrra />
            </div>
            <div className="w-full ">
              <div className="mt-3 space-y-3 max-w-[500px]">
                <p className="text-textGreen font-onestBold text-[16px] ">
                  TOTAL COST
                </p>
                <p className="font-onestRegular text-[14px] md:text-[15px]">
                  The total estimated cost for your delivery is
                </p>
                <div className="border rounded-[8px] px-3 py-3 border-[#C2C5C2] shadow  drop-shadow-2xl  shadow-shadow">
                  <div className="flex space-x-3">
                    <h2 className="font-onestBold text-textGreen text-[28px] md:text-[34px]">
                      {` ₦ 800`}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="mt-10 space-y-2 max-w-[500px] ">
                <Button
                  title="Pay"
                  loading={isMultipleLoading || deleteLoading}
                  className="!py-3"
                  onClick={() => {
                    if (regenerate) {
                      deleteDataOrder();
                    } else {
                      getMultipleData();
                    }
                  }}
                />
                <Button
                  title="Back"
                  className="!py-3 !bg-transparent"
                  onClick={() => setStep(step - 1)}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showPaymentSuccessModal && (
        <DashboardModalAction
          contentTitle={"Track Order Number"}
          content={`This is your track order number, please copy it `}
          btnContent="Close"
          trackNo={
            multipleData?.orderNos[0]
              ? multipleData?.orderNos[0]
              : localStorage.getItem("orderNo")
          }
          onClick={() => {
            setShowPaymentSuccessModal(false);
            handleReload();
            localStorage.removeItem("orderNo");
          }}
        />
      )}

      {showOrderError && (
        <ShowOrderLockYF onClick={() => setShowOrderError(false)} />
      )}

      {showOrderErrorOne && (
        <ShowOrderErrorandD onClick={() => setShowOrderErrorOne(false)} />
      )}

      {showOrderErrorThree && (
        <ShowErrorandYS onClick={() => setShowOrderErrorThree(false)} />
      )}
      {showTerms && (
        <ShowTermsModal
          onClick={() => setShowTerms(false)}
          terms={termsAndCondition?.terms}
          id="formattedText"
        />
      )}
     {lasrraModal &&
      <LasrraInfoModal onClick={()=>setLasrraModal(false)} onShowTerms={() => setShowTerms(true)}/>
     }
    </PageLayout>
  );
};

export default LassraOrder;
