export const Footer = () => {
  return (
    <footer className="  w-full  px-5 sm:px-10 xl:px-14 pt-[20px] font-mon text-left  text-text_primary overflow-y-hidden">
      <div className="py-10 text-text_primary font-mon text-start md:text-left  mb-[30px]">
      <div className="block lg:hidden w-full mb-10">
            <a href="https://www.fezdelivery.co">
              <div className="block mr-6 mb-[35px] -mt-1">
                <img
                  src="/images/fez-logo.svg"
                  className="w-[104px] h-10"
                  alt="logo"
                />
              </div>
            </a>

            <p className="mb-4 text-[14px]  text-[#131313] font-onestMedium">
              +234 906 000 5049
            </p>
            <p className="mb-4 text-[14px]  text-textColor font-onestMedium">
              support@fezdelivery.com
            </p>
            <p className="mb-4 text-[14px]  text-textColor font-onestMedium">
              Moyosore House, 180 Ikorodu Road, <br /> Lagos, Nigeria.
            </p>
          
          </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2  w-full lg:grid-cols-5  ">
          <div className="hidden lg:block w-full">
            <a href="https://www.fezdelivery.co">
              <div className="block mr-6 mb-[35px] -mt-1">
                <img
                  src="/images/fez-logo.svg"
                  className="w-[104px] h-10"
                  alt="logo"
                />
              </div>
            </a>

            <p className="mb-4 text-[14px]  text-[#131313] font-onestMedium">
              +234 906 000 5049
            </p>
            <p className="mb-4 text-[14px]  text-textColor font-onestMedium">
              support@fezdelivery.com
            </p>
            <p className="mb-4 text-[14px]  text-textColor font-onestMedium">
              Moyosore House, 180 Ikorodu Road, <br /> Lagos, Nigeria.
            </p>
          
          </div>

          <div className="lg:mb-0 text-textColor  font-mon mb-[37px]">
            <h6 className="font-onestBold  mb-[15px] text-[15px]   md:justify-start">
              Company
            </h6>
            <p className="mb-4">
              <a
                href=" https://www.fezdelivery.co/about-us"
                className="font-onestMedium  text-textGrey  text-[14px]"
                rel="noreferrer"
              >
                About us
              </a>
            </p>
            <p className="mb-4">
              <a
                href="https://www.linkedin.com/company/fez-delivery-co/jobs/"
                className="font-onestMedium  text-textGrey text-[14px]"
                rel="noreferrer"
              >
                Careers
              </a>
            </p>
            <p className="mb-4">
              <a
                href="https://www.fezdelivery.co/terms-condition"
                className="font-onestMedium  text-textGrey text-[14px]"
                rel="noreferrer"
              >
                Terms of service
              </a>
            </p>
          </div>

          <div className="lg:mb-0  text-textColor mb-[37px]">
            <h6 className="font-onestBold mb-[15px] text-textColor text-[15px]    justify-start">
              Products
            </h6>
            <p className="mb-4">
              <a
                href="https://www.fezdelivery.co/individual"
                className="font-onestMedium  text-textGrey text-[14px]"
                rel="noreferrer"
              >
                Individuals
              </a>
            </p>
            <p className="mb-4">
              <a
                href="https://www.fezdelivery.co/corporates"
                className="font-onestMedium  text-textGrey text-[14px]"
                rel="noreferrer"
              >
                Corporates & Startups
              </a>
            </p>
            <p className="mb-4">
              <a
                href="https://www.fezdelivery.co/sme"
                className="font-onestMedium  text-textGrey text-[14px]"
                rel="noreferrer"
              >
                SME
              </a>
            </p>
            <p className="mb-4">
              <a
                href="https://web.fezdelivery.co/delivery-estimate"
                className="font-onestMedium  text-textGrey text-[14px]"
                rel="noreferrer"
              >
                Get a quote
              </a>
            </p>
          </div>
          <div className="lg:mb-0  text-textColor ">
            <h6 className=" font-onestBold mb-[15px] text-[15px] text-textColor    justify-start">
              Resources
            </h6>
            <p className="mb-4">
              <a
                href="https://www.fezdelivery.co/pricing"
                className="font-onestMedium  text-textGrey text-[14px]"
                rel="noreferrer"
              >
                Pricing
              </a>
            </p>
            <p className="mb-4">
              <a
                href="https://support.fezdelivery.co/support/tickets/new?ticket_form=ask_a_question"
                className="font-onestMedium  text-textGrey text-[14px]"
                target="_blank"
                rel="noreferrer"
              >
                Help center
              </a>
            </p>
            <p className="mb-4">
              <a
                href="https://web.fezdelivery.co/track-delivery"
                className="font-onestMedium  text-textGrey text-[14px]"
                rel="noreferrer"
              >
                Track delivery
              </a>
            </p>
            <p className="mb-4">
              <a
                href="https://www.fezdelivery.co/blog"
                className="font-onestMedium  text-textGrey text-[14px]"
                rel="noreferrer"
              >
               Blog
              </a>
            </p>
          </div>

          <div className="lg:mb-0  text-textColor mb-[37px] ">
            <h6 className=" font-onestBold mb-[15px] text-[15px] text-textColor    justify-start">
              Use cases
            </h6>
            <p className="mb-4">
              <a
                href="https://www.fezdelivery.co/e-commerce"
                className="font-onestMedium  text-textGrey text-[14px]"
                rel="noreferrer"
              >
                E-Commerce
              </a>
            </p>
            <p className="mb-4">
              <a
                href="https://www.fezdelivery.co/beauty"
                className="font-onestMedium  text-textGrey text-[14px]"
                target="_blank"
                rel="noreferrer"
              >
                Beauty
              </a>
            </p>
            <p className="mb-4">
              <a
                href="https://www.fezdelivery.co/fintech"
                className="font-onestMedium  text-textGrey text-[14px]"
                rel="noreferrer"
              >
                Fintech
              </a>
            </p>
            <p className="mb-4">
              <a
                href="https://www.fezdelivery.co/pharma"
                className="font-onestMedium  text-textGrey text-[14px]"
                rel="noreferrer"
              >
                Pharma
              </a>
            </p>
          </div>
         
        </div>
     
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2  w-full lg:grid-cols-5  ">
        <div className="lg:mb-0 text-textColor ">
            <h6 className=" text-blue font-onestBold mb-[15px] text-[15px]">
              Contact
            </h6>
            <p className="mb-4">
              <a
                href="https://support.fezdelivery.co/support/tickets/new?ticket_form=enquiry"
                className="font-onestMedium  text-textGrey  text-[14px]"
                target="_blank"
                rel="noreferrer"
              >
                Contact us
              </a>
            </p>
          </div>
            <div className="flex space-x-5 mb-5">
              <a href="https://instagram.com/fezdeliveryco?igshid=MzRlODBiNWFlZA==">
                <div className="block">
                  <img
                    src="/images/instagram1.png"
                    className="w-7 h-6"
                    alt="logo"
                  />
                </div>
              </a>
              <a href="https://www.linkedin.com/company/fez-delivery-co/">
                <div className="block">
                  <img
                    src="/images/Linkedin1.png"
                    className="w-6 h-7"
                    alt="logo"
                  />
                </div>
              </a>
              <a href="https://x.com/fezdeliveryco?s=21&t=R07rje5BrKqEEmTUu16OaA">
                <div className="block">
                  <img
                    src="/images/twitter1.png"
                    className="w-6 h-6"
                    alt="logo"
                  />
                </div>
              </a>
            </div>
        </div>
      </div>
    </footer>
  );
};
